import { AxiosResponse } from "axios";
import { DefaultResponseListProps } from "../types/config.type";
import { IReportTargetAsset, IReportTargetAssetRequest, IReportTargetHeader, IReportTargetHeaderRequest, IReportTemplate, IReportTemplateRequest } from "../types/masterReport.type";
import { httpRequest } from "../helpers/api";

const MasterReportTemplateSvc = {
  getAllMasterReport(
    params?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<IReportTemplate>>> {
    return httpRequest.get("/master/report_template", {
      params
  })
  },

  getSingleMasterReport(
    mrtpId: string,
  ): Promise<AxiosResponse<IReportTemplate>> {
    return httpRequest.get(`/master/report_template/${mrtpId}`)
  },

  addMasterReport(
    data: IReportTemplateRequest,
  ): Promise<AxiosResponse<IReportTemplate>> {
    return httpRequest.post(`/master/report_template/`, data)
  },

  updateMasterReport(
    data: IReportTemplateRequest,
  ): Promise<AxiosResponse<IReportTemplate>> {
    return httpRequest.put(`/master/report_template/${data.mrtpId}`, data)
  },

  deleteMasterReport(
    mrtpId: string,
  ): Promise<AxiosResponse<IReportTemplate>> {
    return httpRequest.delete(`/master/report_template/${mrtpId}`)
  },
}

const MasterTargetHeaderSvc = {
  getAllTargetHeader(
    params?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<IReportTargetHeader>>> {
    return httpRequest.get("/master/report_target_header", {
      params
  })
  },

  getSingleTargetHeader(
    mrthId: string,
  ): Promise<AxiosResponse<IReportTargetHeader>> {
    return httpRequest.get(`/master/report_target_header/${mrthId}`)
  },

  addTargetHeader(
    data: IReportTargetHeaderRequest,
  ): Promise<AxiosResponse<{
    id: string,
    status: {
      error: number,
      errorCode: number,
      message: string
    }
  }>> {
    return httpRequest.post(`/master/report_target_header/`, data)
  },

  updateTargetHeader(
    data: IReportTargetHeaderRequest,
  ): Promise<AxiosResponse<IReportTargetHeader>> {
    return httpRequest.put(`/master/report_target_header/${data.mrthId}`, data)
  },

  deleteTargetHeader(
    mrthId: string,
  ): Promise<AxiosResponse<IReportTargetHeader>> {
    return httpRequest.delete(`/master/report_target_header/${mrthId}`)
  },
}

const MasterTargetAssetSvc = {
  getAllTargetAsset(
    params?: any
  ): Promise<AxiosResponse<DefaultResponseListProps<IReportTargetAsset>>> {
    return httpRequest.get("/master/report_target_asset", {
      params
  })
  },

  getSingleTargetAsset(
    mrtaId: string,
  ): Promise<AxiosResponse<IReportTargetAsset>> {
    return httpRequest.get(`/master/report_target_asset/${mrtaId}`)
  },

  addTargetAsset(
    data: IReportTargetAssetRequest,
  ): Promise<AxiosResponse<IReportTargetAsset>> {
    return httpRequest.post(`/master/report_target_asset/`, data)
  },

  updateTargetAsset(
    data: IReportTargetAssetRequest,
  ): Promise<AxiosResponse<IReportTargetAsset>> {
    return httpRequest.put(`/master/report_target_asset/${data.mrtaId}`, data)
  },

  deleteTargetAsset(
    mrtaId: string,
  ): Promise<AxiosResponse<IReportTargetAsset>> {
    return httpRequest.delete(`/master/report_target_asset/${mrtaId}`)
  },
}

export {
  MasterReportTemplateSvc,
  MasterTargetHeaderSvc,
  MasterTargetAssetSvc
}