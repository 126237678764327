import { DatePicker, Popover, Select } from 'antd';
import moment, { Moment, unitOfTime } from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';
import { SwapRightOutlined } from '@ant-design/icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useAsset from '../hooks/useAsset';
import { getTimezone } from '../helpers/asset';

type ValuePiece = Date | null | Moment;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  custom: boolean;
  startAt: Moment | Date | string;
  endAt: Moment | Date | string;
  disabled?: boolean;
  onChange: (e: any) => void;
  onChangeCustomTime: (e: any, type: string) => void;
  onChangeCustomRange: (date: Value) => void;
  constanta: number;
  timezone: string;
}

const { RangePicker } = DatePicker;
const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const systemTimezone = (new Date().getTimezoneOffset() / 60) * -1;
const sysOffsetString = String(7 * 60);
 
const DateRangePicker: React.FC<IProps> = (props) => {
  const hours = useMemo(() => {
    return [...Array(24).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  const minutes = useMemo(() => {
    return [...Array(60).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  function onChangeTime(value: number, type: string, timeType: unitOfTime.All) {
    props.onChangeCustomTime(
      moment(type === 'start' ? props.startAt : props.endAt).set(
        timeType,
        value
      ),
      type
    );
  }

  const range = useMemo(() => {
    const start = {
      formated: moment(props.startAt).utcOffset(sysOffsetString).format(dateTimeFormat),
      notFormat: moment(props.startAt).utcOffset(sysOffsetString),
      raw: props.startAt,
    };
    const end = {
      formated: moment(props.endAt).utcOffset(sysOffsetString).format(dateTimeFormat),
      notFormat: moment(props.endAt).utcOffset(sysOffsetString),
      raw: props.endAt,
    };

    return {
      start,
      end,
    };
  }, [props.startAt, props.endAt]);

  if (!props.custom) {
    return (
      <RangePicker
        style={{ width: '100%' }}
        disabled={props.disabled}
        showTime
        value={[
          moment(props.startAt, dateTimeFormat),
          moment(props.endAt, dateTimeFormat),
        ]}
        format='DD MMM YYYY HH:mm'
        onChange={props.onChange}
      />
    );
  }

  function onChangeCalendar2(value: any) {
    if (value) {
      props.onChangeCustomRange(value);
    }
  }

  const content = () => {
    return (
      <>
        <div style={{ width: 250 }}>
          <Calendar
            className='custom-calendar'
            selectRange
            returnValue='range'
            onChange={onChangeCalendar2}
            value={[
              range.start.notFormat.format('YYYY-MM-DD'),
              range.end.notFormat.format('YYYY-MM-DD'),
            ]}
          />
        </div>

        <WrapperTime>
          <div>
            <span>Hour</span>
            <Select
              style={{ width: '100%' }}
              options={hours}
              value={moment(range.start.raw).tz(props.timezone).hours()}
              onSelect={(e) => onChangeTime(e, 'start', 'hours')}
            />
          </div>
          <span>:</span>
          <div>
            <span>Minute</span>
            <Select
              style={{ width: '100%' }}
              options={minutes}
              value={moment(range.start.raw).tz(props.timezone).minute()}
              onSelect={(e) => onChangeTime(e, 'start', 'minutes')}
            />
          </div>
        </WrapperTime>

        <WrapperTime>
          <div>
            <span>Hour</span>
            <Select
              style={{ width: '100%' }}
              options={hours}
              value={moment(range.end.raw).tz(props.timezone).hours()}
              onSelect={(e) => onChangeTime(e, 'end', 'hours')}
            />
          </div>
          <span>:</span>

          <div>
            <span>Minute</span>
            <Select
              style={{ width: '100%' }}
              options={minutes}
              value={moment(range.end.raw).tz(props.timezone).minute()}
              onSelect={(e) => onChangeTime(e, 'end', 'minutes')}
            />
          </div>
        </WrapperTime>
      </>
    );
  };

  return (
    <ContainerRangePicker>
      <Popover
        popupVisible={props.disabled}
        placement='bottom'
        content={() => content()}
        trigger='click'
      >
        <CalendarInput disabled={props.disabled}>
          {moment(range.start.raw).tz(props.timezone).format(dateTimeFormat)}
          <SwapRightOutlined />
          {moment(range.end.raw).tz(props.timezone).format(dateTimeFormat)}
        </CalendarInput>
      </Popover>
    </ContainerRangePicker>
  );
};

const WrapperTime = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
`;

const ContainerRangePicker = styled.div`
  width: 100%;
`;

interface ICalendarInput {
  disabled?: boolean;
}

const CalendarInput = styled.div<ICalendarInput>`
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  height: 32px;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? 'lightgray' : 'white')};
  color: ${(props) => (props.disabled ? 'gray' : 'black')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
`;

export default DateRangePicker;
