import { MoreHorizontal } from 'react-feather';
import styled from 'styled-components';
import useCompany from '../hooks/useCompany';
import { IAssetData } from '../types/asset.type';
import useAuth from '../hooks/useAuth';
import { Dropdown, MenuProps } from 'antd';
import { baseUrl } from '../helpers/api';

interface Props {
  data: IAssetData;
  onClick: (e: string) => void;
  onChooseMenu: (e: string) => void;
}

const CardAssetItem: React.FC<Props> = (props) => {
  const { activeCompany } = useCompany();
  const { handlePermission } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit',
      disabled: !handlePermission(['master_asset'], 'update'),
    },
    {
      key: 'delete',
      label: 'Delete',
      style: { color: 'red' },
      disabled: !handlePermission(['master_asset'], 'delete'),
    },
  ].filter((item) => !item.disabled);

  return (
    <CardContainer>
      <CardHeader>
        <div>
          <Title>{props?.data?.massName}</Title>
          <SubTitle>{activeCompany?.compName}</SubTitle>
        </div>
        <div>
          <Dropdown
            menu={{ items, onClick: (e) => props.onChooseMenu(e.key) }}
            placement='bottomRight'
          >
            <MoreHorizontal size={14} color='#768499' />
          </Dropdown>
        </div>
      </CardHeader>
      <img
        alt='asset-image'
        src={
          props.data.massImage
            ? `${baseUrl}/${props.data.massImage}`
            : '/images/empty-image.png'
        }
      />
      <CardInfo>
        <div>
          <label>Asset ID</label>
          <p>{props.data.massId}</p>
        </div>
        <div>
          <label>Total Device</label>
          <p>{props.data.massDevice ? props.data.massDevice.length : '-'}</p>
        </div>
        <div>
          <label>System Type</label>
          <p>{props.data.massSystemType}</p>
        </div>
      </CardInfo>
      <Divider />
      {(handlePermission(['master_asset'], 'create') ||
        handlePermission(['master_device'], 'read')) && (
        <CardAction
          onClick={() => {
            props.onClick(props?.data?.massId);
          }}
        >
          See Detail Asset
        </CardAction>
      )}
    </CardContainer>
  );
};

export default CardAssetItem;

const CardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  overflow: hidden;
  padding: 12px;
  display: grid;
  gap: 14px;
  grid-template-rows: auto 160px auto;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.05);
  img {
    height: 160px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const CardAction = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.info_Default};
  font-size: 12px;
  font-weight: 600;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
`;

const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  label {
    display: block;
    font-size: 10px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
  p {
    font-size: 14px;
    margin: 0;
  }
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const SubTitle = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;
