import styled from 'styled-components';
import { Grid, PhoneCall, User } from 'react-feather';
import CompanyImage from '../../../components/CompanyImage';
import useAuth from '../../../hooks/useAuth';
import { LoadingWrapper } from '../../report';
import { Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  logoUrl?: string;
  companyId: string;
  companyName: string;
  companyGroupName: string;
  companyPIC: string | undefined;
  companyPICPhone: string | undefined;
  companyContact: string | undefined;
  changeCompany: () => void;
  isLoading: boolean;
  compImage?: string;
}

const MapSummaryCompanyInfo: React.FC<Props> = (props) => {
  const { handlePermission } = useAuth();

  if (!props.companyName || !handlePermission(['master_company'], 'read')) {
    return (
      <EmptyCompanyData>
        <InfoCircleOutlined style={{ fontSize: 24, marginBottom: 12 }} />
        <div>User doesn't have access to read company data</div>
      </EmptyCompanyData>
    );
  }

  return (
    <CompanyInfoContainer>
      <CompanyInfo>
        <div>
          <CompanyImage compImage={props.compImage} />
          <CompanyName>{props.companyName}</CompanyName>
        </div>
      </CompanyInfo>
      <CompanyContact>
        <CompantContactItem>
          <ItemLabel>
            <Grid size={15} color={'#C5CDDB'} />
            <span>Group</span>
          </ItemLabel>
          <p>{props.companyGroupName}</p>
        </CompantContactItem>

        <CompantContactItem>
          <ItemLabel>
            <User size={15} color={'#C5CDDB'} />
            <span>Company PIC</span>
          </ItemLabel>
          {props.companyPIC ? (
            <p>{props.companyPIC}</p>
          ) : (
            <NotSet>Not set</NotSet>
          )}
        </CompantContactItem>

        <CompantContactItem>
          <ItemLabel>
            <User size={15} color={'#C5CDDB'} />
            <span>Company PIC Phone</span>
          </ItemLabel>
          {props.companyPICPhone ? (
            <p>{props.companyPICPhone}</p>
          ) : (
            <NotSet>Not set</NotSet>
          )}
        </CompantContactItem>

        <CompantContactItem>
          <ItemLabel>
            <PhoneCall size={15} color={'#C5CDDB'} />
            <span>Company Phone</span>
          </ItemLabel>
          {props.companyContact ? (
            <p>{props.companyContact}</p>
          ) : (
            <NotSet>Not set</NotSet>
          )}
        </CompantContactItem>
      </CompanyContact>

      {props.isLoading ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        handlePermission(['master_company'], 'read') && (
          <CompanyAction>
            <ButtonCompany onClick={props.changeCompany}>
              Change Company
            </ButtonCompany>
          </CompanyAction>
        )
      )}
    </CompanyInfoContainer>
  );
};

const EmptyCompanyData = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  opacity: 0.5;
  height: 300px;
  background-color: #d7d7d7;
`;

const CompanyInfoContainer = styled.div`
  border-bottom: 6px solid ${(props) => props.theme.colors.grayLighten_Light};
`;

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  background-image: url('/images/background-company.svg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  div {
    width: 330px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.grayLighten_Dark};
  }
`;

const CompanyName = styled.div`
  font-size: ${(props) => props.theme.fontSize.body1} !important;
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

const CompanyContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
`;

const CompantContactItem = styled.div`
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 0px;

  p {
    margin: 0;
    font-size: 13px;
  }
`;

const ItemLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.grayDarken_Default};

  p {
    margin: 0;
    font-size: 13px;
  }

  span {
    font-size: 13px;
  }
`;

const CompanyAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
`;

const ButtonCompany = styled.button`
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  font-size: ${(props) => props.theme.fontSize.body2};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  width: 330px;
`;

const NotSet = styled.div`
  color: ${(props) => props.theme.colors.grayDarken_Light};
  font-style: italic;
`;

export default MapSummaryCompanyInfo;
