import React, { useEffect, useState } from 'react';
import { Button, Layout, Select, Tooltip } from 'antd';
import {
  InfoCircleFilled,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import { appVersion, thisYear } from '../../helpers/constant';
import useConfigApp from '../../hooks/useConfigApp';
import AppLogo from '../../components/AppLogo';
import Breadcrumbs from '../../components/Breadcrumbs';
import Sidebar from '../../components/Sidebar';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as Dashboardsvg } from '../../assets/images/Dashboard3.svg';
import { ReactComponent as Reportsvg } from '../../assets/images/File.svg';
import { ReactComponent as Groupsvg } from '../../assets/images/Apps.svg';
import { ReactComponent as Companysvg } from '../../assets/images/Building.svg';
import { ReactComponent as Shipsvg } from '../../assets/images/kapal.svg';
import { ReactComponent as Devicesvg } from '../../assets/images/Mobile.svg';
import { ReactComponent as Usersvg } from '../../assets/images/User Group.svg';
import { ReactComponent as Rolesvg } from '../../assets/images/User Check.svg';
import { ReactComponent as EmailSvg } from '../../assets/images/Email.svg';
import { ChevronDown } from 'react-feather';
import ModalChangeApp from '../../components/ModalChangeApp';
import useMapConfig from '../../hooks/useMapConfig';
import AppIcon from '../../components/AppIcon';
import ToggleDataSource from '../../components/ToggleDataSource';
import useAsset from '../../hooks/useAsset';
import { getTimezone } from '../../helpers/asset';
import useCompany from '../../hooks/useCompany';

const { Header, Sider, Content } = Layout;

type Props = {
  children: React.ReactNode;
};

const messageDataSource =
  'Choose data source. You can switch data source of this page between Satellite or GSM';

const AppLayout: React.FC<Props> = ({ children }) => {
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const { selectedApp } = useMapConfig();
  const location = useLocation();
  const isHome = location.pathname.includes('home');
  const isHomeFMS = location.pathname === '/home';
  const [openModalApps, setOpenModalApps] = useState<boolean>(false);
  const { currentDataTimezone, setCurrentTimezone, asset } = useAsset();
  const { activeCompany } = useCompany();

  const [compId, setCompId] = useState<string | undefined>('');

  useEffect(() => {
    setCompId(activeCompany?.compId);
  }, [activeCompany?.compId]);

  function handleChangeTimezone(value: string) {
    setCurrentTimezone(Number(value));
  }

  useEffect(() => {
    if (!isHome) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHome]);

  const [searchParams, setSearchParams] = useSearchParams();

  const localTimezone = getTimezone();

  const [currTimezone, setCurrTimezone] = useState(localTimezone);

  useEffect(() => {
    if (localTimezone !== currTimezone) {
      setSearchParams([]);
      window.location.reload();
    }
  }, [localTimezone]);

  const _renderTitlePage = () => {
    let component = undefined;
    let path = location.pathname.split('/')[1].split('-')[0];
    const titlePage = location.pathname.includes('master-report')
      ? 'Master Report Email'
      : path.charAt(0).toUpperCase() + path.slice(1);

    let reportName;

    if (path === 'dashboard') {
      component = <Dashboardsvg style={{ width: 20 }} />;
    } else if (path === 'group') {
      component = <Groupsvg style={{ width: 20 }} />;
    } else if (path === 'company') {
      component = <Companysvg style={{ width: 20 }} />;
    } else if (path === 'asset') {
      component = <Shipsvg style={{ width: 20 }} />;
    } else if (path === 'master') {
      component = <EmailSvg style={{ width: 20 }} />;
    } else if (path.toLowerCase().includes('report')) {
      component = <Reportsvg style={{ width: 20 }} />;
      if (location.pathname.includes('report-summary')) {
        reportName = 'Summary';
      } else if (location.pathname.includes('report-vessel-tracking')) {
        reportName = 'Vessel Tracking';
      } else if (location.pathname.includes('report-chart')) {
        reportName = 'Chart';
      } else if (location.pathname.includes('report-data-log')) {
        reportName = 'Data Log';
      }
    } else if (path.toLowerCase().includes('devices')) {
      component = <Devicesvg style={{ width: 20 }} />;
    } else if (path === 'users') {
      component = <Usersvg style={{ width: 20 }} />;
    } else if (path === 'roles') {
      component = <Rolesvg style={{ width: 20 }} />;
    }

    return (
      <TitlePage>
        {component}
        <Title>
          {titlePage} {reportName && `- ${reportName}`}
        </Title>
      </TitlePage>
    );
  };

  return (
    <Layout style={{ height: '100vh', flexDirection: 'row' }}>
      <AppSider
        breakpoint='lg'
        onCollapse={toggleSidebar}
        trigger={null}
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={70}
        width={250}
        style={{ height: '100vh', overflowY: 'auto' }}
      >
        <AppLogo collapsed={sidebarCollapsed} />
        <Sidebar />
      </AppSider>
      {sidebarCollapsed ? (
        <Tooltip placement='right' title={'Expand Sidebar'}>
          <Button
            style={{
              position: 'absolute',
              bottom: 0,
              height: 40,
              width: 70,
            }}
            onClick={() => toggleSidebar()}
            type='text'
            icon={
              <RightCircleOutlined color='#FFFFFF' className='icon-collapsed' />
            }
          />
        </Tooltip>
      ) : (
        <MinimizeButton
          onClick={() => toggleSidebar()}
          style={{
            position: 'absolute',
            bottom: 0,
            height: 40,
            width: 250,
          }}
        >
          <div>
            v{appVersion} · ©{thisYear}
          </div>
          <LeftCircleOutlined color='#FFFFFF' />
        </MinimizeButton>
      )}

      <Layout
        id='scroll-content'
        className='site-layout'
        style={{ height: '100vh', overflowY: 'scroll' }}
      >
        {isHomeFMS ? (
          false
        ) : (
          <AppHeader>
            {_renderTitlePage()}
            <BreadcrumbContainer>
              <Breadcrumbs />
            </BreadcrumbContainer>
            <AppSwitchWrapper>
              <Tooltip title={messageDataSource}>
                <InfoCircleFilled style={{ fontSize: 14 }} />
              </Tooltip>
              <ToggleDataSource />
            </AppSwitchWrapper>
            <AppSwitchWrapper onClick={() => setOpenModalApps(true)}>
              <AppIcon
                id={selectedApp?.id || ''}
                style={{ width: 20, height: 20 }}
              />
              <TitleLabel>{selectedApp?.name}</TitleLabel>
              <ChevronDown color={'#9FACBF'} size={14} />
            </AppSwitchWrapper>
            <AppSwitchWrapper>
              {compId === '15' ? (
                <Select
                  value={currentDataTimezone.toString()}
                  style={{ width: 120 }}
                  size='small'
                  onChange={handleChangeTimezone}
                  options={[
                    { value: '7', label: 'GMT +7' },
                    { value: '8', label: 'GMT +8' },
                    // { value: '9', label: 'GMT +9' },
                  ]}
                  disabled={(new Date().getTimezoneOffset() / 60) * -1 === 8}
                />
              ) : (
                <> GMT +{currentDataTimezone.toString()} </>
              )}
            </AppSwitchWrapper>
            <AppHeaderAccount>
              <UserAvatar />
            </AppHeaderAccount>
          </AppHeader>
        )}
        <AppContent style={{ padding: 0 }}>{children}</AppContent>
      </Layout>

      <ModalChangeApp
        open={openModalApps}
        onCloseModal={() => setOpenModalApps(false)}
      />
    </Layout>
  );
};

const AppContent = styled(Content)`
  height: 100%;
  padding: 10px 20px 20px 20px;
`;

const AppSider = styled(Sider)`
  background-color: #272a35;
  overflow: auto;
  overflow-x: hidden;
`;

const AppHeader = styled(Header)`
  position: relative;
  padding: 0;
  height: 50px;
  display: flex;
  align-items: flex-start;
  grid-template-columns: auto auto 1fr auto auto auto auto auto auto;
  gap: 1px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  box-shadow: 0px 4px 2px rgba(197, 205, 219, 0.19);
  position: sticky;
  align-items: flex-start;
  top: 0;
  z-index: 9999;
`;

const AppHeaderAccount = styled.div`
  display: flex;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  height: 100%;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
`;

const MinimizeButton = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  cursor: pointer;

  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const TitlePage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    width: 24px;
    height: 24px;
  }
  path,
  rect {
    stroke: #0957c2 !important;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  flex: 1;

  span {
    line-height: 1.5;
  }
`;

const AppSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
`;

const TitleLabel = styled.div`
  font-size: 12px;
`;

const Spacer = styled.div`
  background-color: white;
  height: 100%;
`;

export const ContainerContent = styled.div`
  padding: 20px;
`;

export default AppLayout;
