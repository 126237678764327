import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import FilterDashboard from "../dashboard/components/FilterDashboard";
import useFilter from "../../hooks/useFilter";
import useAsset from "../../hooks/useAsset";
import SummarySvc from "../../services/SummarySvc";
import moment from "moment";
import useCompany from "../../hooks/useCompany";
import { generateTimestampArray, toHHMMSS } from "../../helpers/dateUtil";
import { Table } from "antd";
import { useVT } from "virtualizedtableforantd4";
import DeviceSvc from "../../services/DeviceSvc";

const ReportOperationMode: React.FC = () => {
  interface OperationModeData {
    [key: string]: {
      value: number;
      runningSeconds: number;
      maxRpm: string;
    };
  }

  const [operationModeSummary, setOperationModeSummary] = useState<any>([]);
  const [operationModeSeries, setOperationModeSeries] = useState<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const { dataFilter } = useFilter();
  const { assets2, fetchAssets } = useAsset();
  const { fetchCompanyData, activeCompany } = useCompany();

  const windowHeight = window.innerHeight;
  const [vt, set_components] = useVT(
    () => ({ scroll: { y: windowHeight - 300, x: 700 } }),
    []
  );

  const dataFetchedRef = useRef(false);

  const currentAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter.massId, assets2]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const fetchOperationMode = async () => {
    if(!currentAsset?.isHaveOperationMode) return;
    const devc = currentAsset?.massDevice.find(
      (elm: any) => elm.devcType === "rpm"
    );

    const responseSeries = await SummarySvc.getSeriesOperationMode({
      start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      params: { devcId: devc?.devcId },
    });

    const responseSummary = await SummarySvc.getSummaryOperationMode({
      start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      params: { devcId: devc?.devcId },
    });

    const dataTotal: any = responseSummary.data?.data?.total;

    const start = moment(dataFilter.range.startAt).unix();
    const end = moment(dataFilter.range.endAt).unix();
    const series = responseSeries?.data?.data?.data;
    let newCategories: any[] = generateTimestampArray(start, end, 60);

    const data = newCategories.map((timestamp) => {
      const currData =  series[timestamp];
      return {
        id: timestamp,
        dateTime: moment(timestamp * 1000).format('DD MMM YYYY, HH:mm:ss'),
        stbOpsMode: currData?.STARBOARD?.mode,
        stbRPM: currData?.STARBOARD?.rpm,
        stbFuelCons: currData?.STARBOARD?.value,
        portOpsMode: currData?.PORT?.mode,
        portRPM: currData?.PORT?.rpm,
        portFuelCons: currData?.PORT?.value,
      }
    });

    if (devc?.devcId) {
      if (responseSeries?.data?.data) {
        setOperationModeSeries(data);
      }
      if (responseSummary?.data?.data) {
        setOperationModeSummary(dataTotal);
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    try {
      fetchOperationMode();
    } catch (error) {
      console.error("failed get operation data", error);
    }
  }, [dataFilter]);

  const tableColumns: any = [
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      key: "dateTime",
      width: 180,
      fixed: "left",
      align: "center",
    },
    {
      title: "Starboard",
      fixed: "left",
      align: "center",
      children: [
        {
          title: "RPM",
          dataIndex: "stbRPM",
          key: "stbRPM",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
        {
          title: "Ops. Mode",
          dataIndex: "stbOpsMode",
          key: "stbOpsMode",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : value
        },
        {
          title: "Fuel Cons",
          dataIndex: "stbFuelCons",
          key: "stbFuelCons",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
      ],
    },
    {
      title: "Center",
      fixed: "left",
      align: "center",
      hide: !currentAsset?.isThreeEngine,
      children: [
        {
          title: "RPM",
          dataIndex: "ctrRPM",
          key: "ctrRPM",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
        {
          title: "Ops. Mode",
          dataIndex: "ctrOpsMode",
          key: "ctrOpsMode",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : value
        },
        {
          title: "Fuel Cons",
          dataIndex: "ctrFuelCons",
          key: "ctrFuelCons",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
      ],
    },
    {
      title: "PORT",
      fixed: "left",
      align: "center",
      children: [
        {
          title: "RPM",
          dataIndex: "portRPM",
          key: "portRPM",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
        {
          title: "Ops. Mode",
          dataIndex: "portOpsMode",
          key: "portOpsMode",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : value
        },
        {
          title: "Fuel Cons",
          dataIndex: "portFuelCons",
          key: "portFuelCons",
          width: 100,
          fixed: "left",
          align: "center",
          render: (value: any) => typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2)
        },
      ],
    },
  ].filter((item) => !item.hide)
  .map((item) => {
    if (item.children) {
      return {
        ...item,
        children: item.children.filter((child: any) => !child.hide),
      };
    } else {
      return item;
    }
  });

  return (
    <React.Fragment>
      <ReportActionContent>
        <FilterDashboard
          isLog
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
      </ReportActionContent>

      <Container>
        {currentAsset?.opsModeTemplate && operationModeSummary ? (
          <div style={{ marginTop: "20px", paddingBottom: "30px" }}>
            <table id="summaryTable" className="custom-table">
              <thead>
                <tr>
                  <th>Operating Mode</th>
                  <th>Running Time</th>
                  <th>Fuel Cons</th>
                </tr>
              </thead>
              <tbody>
                {currentAsset?.opsModeTemplate && currentAsset?.opsModeTemplate.map((elm, idx: number) => {
                  const foundData = operationModeSummary.mode ? operationModeSummary.mode[elm.mrodName] : undefined;
                  if(foundData){
                  return (
                      <tr key={idx}>
                        <td>{elm.mrodName}</td>
                        <td>{toHHMMSS(foundData.runningSeconds)}</td>
                        <td>{foundData.value.toFixed(2)}</td>
                      </tr>
                    )
                  }else{
                    return (
                      <tr key={idx}>
                        <td>{elm.mrodName}</td>
                        <td>{toHHMMSS(0)}</td>
                        <td>{0}</td>
                      </tr>
                    )
                  }
                })}
                <tr key={'total'}>
                  <td>Total</td>
                  <td>{toHHMMSS(operationModeSummary.runningSeconds)}</td>
                  <td>{operationModeSummary.value && operationModeSummary.value.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            No Operation Mode Data
          </p>
        )}

        <Table
          className="custom-small-table"
          style={{ marginTop: 5 }}
          size="small"
          bordered
          columns={tableColumns}
          pagination={{
            pageSize: 500,
          }}
          scroll={{ y: windowHeight - 300, x: 700 }}
          dataSource={operationModeSeries}
          components={vt}
        />
      </Container>
    </React.Fragment>
  );
};

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: flex-end;
  padding-right: 20px;
`;

const Container = styled.div`
  padding: 20px;
  background-color: #E8ECF3;
`;

export default ReportOperationMode;
