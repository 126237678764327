import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  message,
  Switch,
  DatePicker,
} from 'antd';
import { IOperationHeader } from '../../../types/operationMode.type';
import useAuth from '../../../hooks/useAuth';
import { MasterReportTemplateSvc } from '../../../services/MasterReportSvc';
import useAsset from '../../../hooks/useAsset';
import moment from 'moment';
import useCompany from '../../../hooks/useCompany';
import GroupSvc from '../../../services/GroupSvc';
import { IGroupCompanyData } from '../../../types/groupCompany.type';
import CompanySvc from '../../../services/CompanySvc';
import { ICompanyData } from '../../../types/company.type';
import { AssetType, IAssetData } from '../../../types/asset.type';
import AssetSvc from '../../../services/AssetSvc';
import { DeviceType } from '../../../types/device.type';
import DeviceSvc from '../../../services/DeviceSvc';
import MasterOperatrionModeSvc from '../../../services/OperationModeSvc';

interface Props {
  isOpen: boolean;
  data?: IOperationHeader;
  afterSubmit: () => void;
  compId: string;
}

const ModalOperationMode: React.FC<Props> = ({
  isOpen,
  data,
  afterSubmit,
  compId,
}) => {
  const { handlePermission } = useAuth();
  const [form] = Form.useForm<IOperationHeader>();
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const { assets2, fetchAssets } = useAsset();
  const { availableCompanies, activeCompany } = useCompany();

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  async function handleOnSubmit() {
    try {
      setLoadingSubmit(true);
      const values = await form.validateFields();
      const currentData = {
        mrohCompId: values.mrohCompId,
        mrohDevcId: values.mrohDevcId,
        mrohMassId: values.mrohMassId,
        mrohStartTime: moment(values.mrohStartTime).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        mrohStatus: values.mrohStatus ? '1' : '0',
      };

      if (data) {
        await MasterOperatrionModeSvc.updateMasterHeader(
          data.mrohId,
          currentData
        );
      } else {
        await MasterOperatrionModeSvc.addMasterHeader(currentData);
      }

      handleReset();
    } catch (error) {
      console.error('failed submit operation mode', error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  function handleReset() {
    form.resetFields();
    afterSubmit();
  }

  function generateMappedData(data: Array<any>, value: string, label: string) {
    if (data && data.length > 0) {
      return data.map((item: any) => ({
        value: item[value],
        label: item[label],
      }));
    }

    return [];
  }

  const [loadingFetchGroup, setLoadingFetchGroup] = useState(false);
  const [allGroups, setAllGroups] = useState<IGroupCompanyData[]>([]);

  useEffect(() => {
    async function getAllGroup() {
      try {
        setLoadingFetchGroup(true);
        const response = await GroupSvc.getAll({
          page: 1,
          max: 100,
        });
        if (response.data && response.data.data) {
          setAllGroups(response.data.data);
          if (availableCompanies && activeCompany) {
            const foundCurrentGroup = availableCompanies.find(
              (item) => item.compId === activeCompany?.compId
            );
            if (foundCurrentGroup) {
              form.setFieldValue('grcpId', foundCurrentGroup.compGrcpId);
              await handleGetCompany(foundCurrentGroup.compGrcpId);
            }
          }
        }
      } catch (error) {
        console.error('failed fetch groups', error);
      } finally {
        setLoadingFetchGroup(false);
      }
    }

    if (availableCompanies || activeCompany || data) {
      getAllGroup();
    }
  }, [availableCompanies, activeCompany, data]);

  const groupMapped = useMemo(() => {
    return generateMappedData(allGroups, 'grcpId', 'grcpName');
  }, [allGroups]);

  const [loadingFetchCompany, setLoadingFetchCompany] = useState(false);
  const [allCompanies, setAllCompanies] = useState<ICompanyData[]>([]);

  async function handleGetCompany(grcpId: string) {
    try {
      setLoadingFetchCompany(true);
      const response = await CompanySvc.getCompanies({
        params: {
          page: 1,
          max: 100,
          findField: 'compGrcpId',
          findValue: grcpId,
        },
      });
      if (response.data && response.data.data) {
        setAllCompanies(response.data.data);
        if (activeCompany) {
          form.setFieldValue('mrohCompId', activeCompany?.compId);
          await handleGetAssetsByCompId(activeCompany?.compId);
        }
      }
    } catch (error) {
      console.error('failed fetch companies', error);
    } finally {
      setLoadingFetchCompany(false);
    }
  }

  const companyMapped = useMemo(() => {
    return generateMappedData(allCompanies, 'compId', 'compName');
  }, [allCompanies]);

  const [loadingFetchAsset, setLoadingFetchAsset] = useState(false);
  const [allAssets, setAllAsset] = useState<AssetType[]>([]);

  async function handleGetAssetsByCompId(compId: string) {
    try {
      setLoadingFetchAsset(true);
      const response = await AssetSvc.getAssets({
        params: {
          page: 1,
          max: 100,
          findField: 'massCompId',
          findValue: compId,
        },
      });
      if (response.data && response.data.data) {
        setAllAsset(response.data.data);
      }
    } catch (error) {
      console.error('failed fetch assets by compId', error);
    } finally {
      setLoadingFetchAsset(false);
    }
  }

  const assetMapped = useMemo(() => {
    return generateMappedData(allAssets, 'massId', 'massName');
  }, [allAssets]);

  const [loadingFetchDevice, setLoadingFetchDevice] = useState(false);
  const [allDevices, setAllDevice] = useState<DeviceType[]>([]);

  async function handleGetDevicesByMassId(massId: string) {
    try {
      setLoadingFetchDevice(true);
      const response = await DeviceSvc.getDevices({
        params: {
          page: 1,
          max: 100,
          findField: 'devcMassId',
          findValue: massId,
        },
      });
      if (response.data && response.data.data) {
        setAllDevice(response.data.data);
      }
    } catch (error) {
      console.error('failed fetch assets by compId', error);
    } finally {
      setLoadingFetchDevice(false);
    }
  }

  const deviceMapped = useMemo(() => {
    return generateMappedData(allDevices, 'devcId', 'devcName');
  }, [allDevices]);

  async function handleFetchDataEdit(mrohMassId: string) {
    await handleGetDevicesByMassId(mrohMassId);
  }

  useEffect(() => {
    if (data) {
      console.log('data', data);
      form.setFieldValue('mrohCompId', data.mrohDevcId);
      form.setFieldValue('mrohDevcId', data.mrohDevcId);
      form.setFieldValue('mrohMassId', data.mrohMassId);
      form.setFieldValue('mrohStatus', data.mrohStatus === '1' ? true : false);
      form.setFieldValue('mrohStartTime', moment(data.mrohStartTime));
      handleFetchDataEdit(data.mrohMassId);
    }
  }, [data]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  return (
    <Modal
      width={'45%'}
      title={
        data && data.mrohId
          ? 'Edit Operation Mode Detail'
          : 'Add Operation Mode Detail'
      }
      open={isOpen}
      onCancel={handleReset}
      className='custom-modal'
      footer={[
        <Button
          style={{ background: '#FFFFFF', color: '#000000' }}
          onClick={handleReset}
        >
          Cancel
        </Button>,
        <Button
          loading={loadingSubmit}
          disabled={
            (!handlePermission(['master_report_template'], 'create') &&
              !handlePermission(['master_report_template'], 'update')) ||
            disabledSave
          }
          onClick={handleOnSubmit}
          type='primary'
        >
          {data && data.mrohId ? 'Update' : 'Create'}
        </Button>,
      ]}
    >
      <Form layout='vertical' onFieldsChange={handleFormChange} form={form}>
        <Form.Item
          label='Group'
          name='grcpId'
          rules={[{ required: true, message: 'Please Select Group!' }]}
        >
          <Select
            disabled
            placeholder='Select group'
            loading={loadingFetchGroup}
            showSearch
            // onChange={(e) => handleGetCompany(e)}
            optionFilterProp='children'
            options={groupMapped}
          />
        </Form.Item>
        <Form.Item
          label='Company'
          name='mrohCompId'
          rules={[{ required: true, message: 'Please Select Company!' }]}
        >
          <Select
            disabled
            placeholder='Select company'
            loading={loadingFetchCompany}
            showSearch
            optionFilterProp='children'
            options={companyMapped}
            // onChange={(e) => handleGetAssetsByCompId(e)}
          />
        </Form.Item>
        <Form.Item
          label='Assets'
          name='mrohMassId'
          rules={[{ required: true, message: 'Please Select Asset!' }]}
        >
          <Select
            placeholder='Select asset'
            loading={loadingFetchAsset}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').toString().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toString()
                .toLowerCase()
                .localeCompare(
                  (optionB?.label.toLowerCase() ?? '').toLowerCase()
                )
            }
            showSearch
            optionFilterProp='children'
            options={assetMapped}
            onChange={(e) => handleGetDevicesByMassId(e)}
          />
        </Form.Item>
        <Form.Item
          label='Device Id'
          name='mrohDevcId'
          rules={[{ required: true, message: 'Please Select Device!' }]}
        >
          <Select
            placeholder='Select device'
            loading={loadingFetchDevice}
            showSearch
            optionFilterProp='children'
            options={deviceMapped}
          />
        </Form.Item>
        <Form.Item
          label='Start Time'
          name='mrohStartTime'
          rules={[{ required: true, message: 'Please input Start Time!' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime
            defaultValue={moment()}
            // value={
            //   data?.mrohCreatedTime
            //     ? moment(data.mrohCreatedTime, 'YYYY-MM-DD HH:mm:ss')
            //     : moment()
            // }
          />
        </Form.Item>
        <Form.Item
          label='Status (ON/OFF)'
          name='mrohStatus'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalOperationMode;
