import axios, { AxiosInstance } from 'axios';
import React, { useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
  IResponseSingle,
  INITIAL_PAGINATION,
  INITIAL_QUERY,
  IPagination,
  IResponsePagination,
  IQuery,
} from '../helpers/pagination';

type Props = {
  endpoint: string;
  initialQuery?: Object;
  page?: number;
  pushData?: boolean;
  apiRequest?: AxiosInstance;
  fetchable?: boolean;
  params?: any;
};

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType, ExtendType = {}>(props: Props) {
  const fetchable = props.fetchable === undefined ? true : props.fetchable;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<DataType>>([]);
  const [pagination, setPagination] = useState<IPagination>({
    ...INITIAL_PAGINATION,
    itemPerPage: props.page || DEFAULT_LIMIT,
  } as IPagination);
  const [query, setQuery] = useState<IQuery<ExtendType>>({
    ...INITIAL_QUERY,
    page: props.page || DEFAULT_LIMIT,
    ...props.initialQuery,
  } as IQuery<ExtendType>);
  const [error, setError] = useState<any>();

  const [search, setSearch] = React.useState<{
    findField: string;
    findValue: string;
  }>();
  const [searchValue] = useDebounce(search, 1000);

  const fetchList = async (params?: {
    signal: AbortSignal;
    [T: string]: any;
  }) => {
    try {
      setIsLoading(true);
      const response = await axios.request<IResponsePagination<DataType>>({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        signal: params?.signal,
        method: 'get',
        url: props.endpoint,
        params: { ...query, ...props.params, ...params?.params },
      });

      setPagination((oldVal) => {
        return {
          ...oldVal,
          ...response.data.pagination,
        };
      });

      if (props.pushData) {
        setData((value) => [...value, ...response.data.data]);
      } else {
        setData(response.data.data);
      }
    } catch (error) {
      setError(false);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;

    if (fetchable) {
      fetchList({ signal });
    } else if (
      (query && query.page && query.page > 0) ||
      Object.keys(query).length > 2
    ) {
      fetchList({ signal });
    }

    return () => {
      controller.abort();
    };
  }, [query, fetchable]);

  React.useEffect(() => {
    setQuery((e: IQuery<ExtendType>) => {
      return { ...e, ...searchValue };
    });
  }, [searchValue]);

  const changePage = (page: any, perPage: number) => {
    // setPagination((oldVal) => {
    //   return {
    //     ...oldVal,
    //     page,
    //     itemPerPage: perPage,
    //   };
    // });
    setQuery((oldVal) => {
      return {
        ...oldVal,
        max: perPage,
        page,
      };
    });
  };

  const changeItemPerPage = (max: number) => {
    // setPagination((oldVal) => {
    //   return {
    //     ...oldVal,
    //     itemPerPage: max,
    //   };
    // });
    setQuery((oldVal) => {
      return {
        ...oldVal,
        max,
        page: 0,
      };
    });
  };

  return {
    DEFAULT_LIMIT,
    isLoading,
    data,
    pagination,
    query,
    setData,
    setPagination,
    setQuery,
    search,
    setSearch,
    changePage,
    fetchList,
    setIsLoading,
    changeLimit: changeItemPerPage,
    error,
  };
}
