import { time } from "console";
import { IFlowmeterData } from "../types/summary.type";

export const assetConfig: any = {
  'rpm-mdm_medan1': {
    isSingleEngine: true,
  },
  42: {
    isSingleEngine: true,
  },
};

export const APP_DATA_SOURCE = process.env.REACT_APP_ID + '_data_source';

export function getSource() {
  return localStorage.getItem(APP_DATA_SOURCE);
}

export const APP_DATA_TIMEZONE = process.env.REACT_APP_ID + '_data_timezone';

export function getTimezone(): number {
  const timezone = localStorage.getItem(APP_DATA_TIMEZONE);
  const systemTimezone = (new Date().getTimezoneOffset() / 60) * -1;
  if (!timezone) {
    return Number(systemTimezone);
  }

  return Number(timezone);
}

/**
 * fungsi ini digunakan untuk menghitung custom data device procon (citra 02) di fuelcons dan avg flow
 */
export const proconCustomCalculation = (flowmeterData: IFlowmeterData[] | undefined, unit = 'MINUTE') => {

  if (!flowmeterData) return {
    portTotalFuelCons: '0',
    starboardTotalFuelCons: '0',
    portAverageFlow: '0',
    starboardAverageFlow: '0',
  }

  const portInflows = flowmeterData?.filter((item: any) => item.PORT_InFlow > 0);
  const starboardInflows = flowmeterData?.filter((item: any) => item.STARBOARD_InFlow > 0);

  const portTotalFlow = portInflows?.reduce((acc: number, curr: any) => acc + curr.PORT_InFlow, 0) || 0;
  const starboardTotalFlow = starboardInflows?.reduce((acc: number, curr: any) => acc + curr.STARBOARD_InFlow, 0) || 0;

  const portDataCount = portInflows?.length || 0;
  const starboardDataCount = starboardInflows?.length || 0;

  const portAverageFlow = (portTotalFlow / portDataCount).toFixed(2);
  const starboardAverageFlow = (starboardTotalFlow / starboardDataCount).toFixed(2);

  return {
    portTotalFuelCons: unit === 'MINUTE' ? ((parseFloat(portAverageFlow) / 60) * portDataCount).toFixed(2) : (parseFloat(portAverageFlow) * portDataCount).toFixed(2),
    starboardTotalFuelCons: unit === 'MINUTE' ? ((parseFloat(starboardAverageFlow) / 60) * starboardDataCount).toFixed(2) : (parseFloat(starboardAverageFlow) * starboardDataCount).toFixed(2),
    starboardAverageFlow: starboardAverageFlow,
    portAverageFlow: portAverageFlow
  }
}