export const openDatabase = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('ramus', 1);
    
    request.onerror = (event: Event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
    
    request.onsuccess = (event: Event) => {
      resolve((event.target as IDBOpenDBRequest).result as IDBDatabase);
    };
    
    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
      if (!db.objectStoreNames.contains('filterColumns')) {
        db.createObjectStore('filterColumns', { keyPath: 'id' });
      }
    };
  });
};

export const saveToIndexedDB = async (key: string, data: any): Promise<void> => {
  const db = await openDatabase();
  const transaction = db.transaction(['filterColumns'], 'readwrite');
  const store = transaction.objectStore('filterColumns');
  const request = store.put({ id: key, data: data });

  return new Promise((resolve, reject) => {
    request.onerror = (event: Event) => {
      console.error('Error saving to IndexedDB', (event.target as IDBRequest).error);
      reject((event.target as IDBRequest).error);
    };

    request.onsuccess = () => {
      console.log('Data saved to IndexedDB');
      resolve();
    };
  });
};

export const getFromIndexedDB = async (key: string): Promise<any | undefined> => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['filterColumns']);
    const store = transaction.objectStore('filterColumns');
    const request = store.get(key);

    request.onerror = (event: Event) => {
      console.error('Error retrieving from IndexedDB', (event.target as IDBRequest).error);
      reject((event.target as IDBRequest).error);
    };

    request.onsuccess = (event: Event) => {
      const result = (event.target as IDBRequest).result;
      resolve(result ? result.data : undefined);
    };
  });
};
