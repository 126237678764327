import { createGlobalState } from 'react-hooks-global-state';
import {
  ICompGrcpAuthStatus,
  ICompAuthStatus,
  ICompanyData,
  initialCompanyType,
} from '../types/company.type';
import { useMemo } from 'react';
import AuthSvc from '../services/AuthSvc';
import useAuth from './useAuth';
import CompanySvc from '../services/CompanySvc';

interface IAvailableCompaniesByGroup {
  group: ICompGrcpAuthStatus;
  companies: ICompAuthStatus[];
}

interface CompanyProps {
  availableCompaniesByGroup?: IAvailableCompaniesByGroup[];
  availableCompaniesRaw?: {
    [T: string]: ICompAuthStatus;
  };
  activeCompany?: ICompAuthStatus;
  activeCompanyData: ICompanyData;
  isLoadingCompanyData: boolean;
  isLoadingCompanies: boolean;
}

const initialState: CompanyProps = {
  availableCompaniesByGroup: undefined,
  availableCompaniesRaw: undefined,
  activeCompany: undefined,
  activeCompanyData: { ...initialCompanyType },
  isLoadingCompanyData: false,
  isLoadingCompanies: false,
};

const { useGlobalState } = createGlobalState(initialState);

const useCompany = () => {
  const [availableCompaniesRaw, setAvailableCompaniesRaw] = useGlobalState(
    'availableCompaniesRaw'
  );
  const [activeCompanyData, setActiveCompanyData] =
    useGlobalState('activeCompanyData');
  const [activeCompany, setActiveCompany] = useGlobalState('activeCompany');
  const [isLoadingCompanyData, setIsLoadingCompanyData] = useGlobalState(
    'isLoadingCompanyData'
  );
  const [isLoadingCompanies, setIsLoadingCompanies] =
    useGlobalState('isLoadingCompanies');
  const { loginData } = useAuth();

  const availableCompanies = useMemo(() => {
    if (availableCompaniesRaw) {
      return Object.values(availableCompaniesRaw);
    }
  }, [availableCompaniesRaw]);

  const availableCompaniesByGroup = useMemo(() => {
    let currAvailableCompanies: IAvailableCompaniesByGroup[] = [];

    if (loginData.company?.available || availableCompaniesRaw) {
      currAvailableCompanies = Object.values(
        availableCompaniesRaw
          ? availableCompaniesRaw
          : loginData.company.available
          ? loginData.company.available
          : {}
      ).reduce(
        (
          acc: { group: ICompGrcpAuthStatus; companies: ICompAuthStatus[] }[],
          curr: ICompAuthStatus
        ) => {
          const foundIdx = acc.findIndex(
            (i) => i.group.grcpId === curr.compGrcpId
          );
          if (foundIdx > -1) {
            acc[foundIdx].companies.push({
              compGrcp: curr.compGrcp,
              compGrcpId: curr.compGrcpId,
              compId: curr.compId,
              compName: curr.compName,
              compImage: curr.compImage,
            });
          } else {
            acc.push({
              group: {
                grcpId: curr.compGrcp.grcpId,
                grcpName: curr.compGrcp.grcpName,
                grcpNickName: curr.compGrcp.grcpNickName,
              },
              companies: [
                {
                  compGrcp: curr.compGrcp,
                  compGrcpId: curr.compGrcpId,
                  compId: curr.compId,
                  compName: curr.compName,
                  compImage: curr.compImage,
                },
              ],
            });
          }

          return acc;
        },
        [] as { group: ICompGrcpAuthStatus; companies: ICompAuthStatus[] }[]
      );
      return currAvailableCompanies;
    }

    return [];
  }, [loginData, availableCompaniesRaw]);

  async function setCurrentActiveCompany(params: {
    compId: string;
    compGrcpId?: string;
    setCompany?: boolean;
    getCompany?: boolean;
  }) {
    if (!params.compId) return;
    setIsLoadingCompanyData(true);
    try {
      params.setCompany && (await AuthSvc.setCompany(params.compId));

      if (params.getCompany) {
        await fetchCompanyData(params.compId, (company) => {
          setActiveCompany({
            compGrcp: company.compGrcp,
            compGrcpId: company.compGrcpId,
            compId: company.compId,
            compName: company.compName,
          });
        });
      } else {
        setActiveCompany({
          compGrcp: {
            grcpId: '',
            grcpName: '',
            grcpNickName: '',
          },
          compGrcpId: params.compGrcpId ?? '' ,
          compId: params.compId,
          compName: '',
        });
      }
    } catch (error) {
      console.error('===> failed set active company', error);
    } finally {
      setIsLoadingCompanyData(false);
    }
  }

  // const fetchActiveCompany = async () => {
  //   setIsLoadingCompanyData(true);
  //   try {
  //     const res = await AuthSvc.getUserStatus();

  //     if (res.data && res.data.company) {
  //       setAvailableCompaniesRaw(res.data.company.available);
  //       await setCurrentActiveCompany({
  //         compId: res.data.company.active.toString(),
  //       });
  //     }
  //   } catch (error) {
  //     console.error('===> failed get user status && company', error);
  //   } finally {
  //     setIsLoadingCompanyData(false);
  //   }
  // };

  const fetchCompanyData = async (
    compId: string,
    cb?: (data: ICompanyData) => void
  ) => {
    setIsLoadingCompanyData(true);
    try {
      const res = await CompanySvc.getCompanyById(compId);

      if (res.data && res.data.data && res.data.data.length > 0) {
        setActiveCompanyData(res.data.data[0]);
        if (cb) {
          cb(res.data.data[0]);
        }
      }
    } catch (error) {
      console.error('===> failed get user status && company', error);
    } finally {
      setIsLoadingCompanyData(false);
    }
  };

  return {
    availableCompaniesByGroup,
    activeCompany,
    activeCompanyData,
    isLoadingCompanyData,
    isLoadingCompanies,
    availableCompaniesRaw,
    setActiveCompanyData,
    setIsLoadingCompanyData,
    setIsLoadingCompanies,
    fetchCompanyData,
    setCurrentActiveCompany,
    setAvailableCompaniesRaw,
    availableCompanies,
  };
};

export default useCompany;
