import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { intervalOptions, optionsGauge } from '../../helpers/map';
import BaseMap from '../home/components/BaseMap';
import FilterDashboard from './components/FilterDashboard';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { Spin, Carousel } from 'antd';
import CardInfo from './components/CardInfo';
import { useLocation, useSearchParams } from 'react-router-dom';
import { emsAssets, fixedValueString } from '../../helpers/constant';
import HChartRpmFuelSpeedv2 from '../../components/charts/HChartRpmFuelSpeed';
import useFilter from '../../hooks/useFilter';
import useAsset from '../../hooks/useAsset';
import { InfoCircleOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { logoRamusBase64 } from '../../const/pdf';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import {
  degreesToRadians,
  latitudeFormat,
  longitudeFormat,
} from '../../helpers/map-util';
import { generateHourArray, toHHMMSS, toKnot } from '../../helpers/dateUtil';
import { IAEData, ISummary } from '../../types/summary.type';
import DataCompletionInfo from '../../components/DataCompletionInfo';
import AssetSvc from '../../services/AssetSvc';
import SummarySvc from '../../services/SummarySvc';
import DeviceSvc from '../../services/DeviceSvc';
import { getSource, proconCustomCalculation } from '../../helpers/asset';
import { IOperationDetail } from '../../types/operationMode.type';
import { ModeDataSeries } from '../../types/asset.type';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const Dashboard: React.FC = () => {
  const { isLoadingFilter, dataFilter } = useFilter();
  const {
    assetObject,
    isEMS,
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAsset,
    isLoadingAssetSummary,
    dataCompletion,
    fetchSummary,
    currentDataSource,
    currentDataTimezone,
    currentAsset
  } = useAsset();
  const { fetchCompanyData, activeCompany, activeCompanyData } = useCompany();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const refChart: any = useRef();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  const isDataExist = currAsset;
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine;

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const formatDataRPM = (
    value: 'STARBOARD' | 'PORT' | 'MAINENGINE' | 'CENTER',
    singleEngine?: boolean
  ) => {
    let rpm: number = 0;
    if (currAsset) {
      if (singleEngine) {
        if (currAsset.dataSummary?.rpm?.summary.average.MAINENGINE) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.MAINENGINE;
        } else if (currAsset.dataSummary?.rpm?.summary.average.STARBOARD) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.STARBOARD;
        } else if (currAsset.dataSummary?.rpm?.summary.average.PORT) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.PORT;
        } else if (currAsset.dataSummary?.rpm?.summary.average.CENTER) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.CENTER;
        }
      } else {
        rpm = currAsset.dataSummary?.rpm?.summary.average[value] || 0;
      }
    }
    return rpm;
  };

  const lastUpdatedRPM = (
    value: 'STARBOARD' | 'PORT' | 'MAINENGINE' | 'CENTER',
    singleEngine?: boolean
  ) => {
    if (
      isDataExist &&
      currAsset.dataSummary?.rpm &&
      currAsset.dataSummary.rpm.data.length > 0
    ) {
      const lastRpmData = getLastRpmData(value);
      
      const dateTime = moment(
        new Date(Number(lastRpmData?.timestamp) * 1000)
      ).format('DD MMM yyyy HH:mm');
      return `Last data: ${dateTime}`;
    }

    return `Last data: Not set (Data RPM empty)`;
  };

  const getLastRpmData = (value: any) =>{
    if(isDataExist &&
      currAsset.dataSummary?.rpm &&
      currAsset.dataSummary.rpm.data.length > 0) {
      const rpmData = currAsset.dataSummary?.rpm.data;
      
      for (let i = rpmData.length - 1; i >= 0; i--) {
        const rpmEntry = rpmData[i];

        if (rpmEntry[value] && rpmEntry[value].timestamp) {
          return rpmEntry[value];
        }
      }
    }
  }; 

  const lastFlowMeterData = (data: any, type: string) => {
    if (data) {
      let currData = data[data.length - 1];

      if (type === 'PORT') {
        return currData?.PORT_Flow.toFixed(2) ?? '-';
      } else if (type === 'STARBOARD') {
        return currData?.STARBOARD_Flow.toFixed(2) ?? '-';
      } else {
        return '-';
      }
    }
    return '-';
  };

  async function handleDownloadPDF() {
    setLoadingDownload(true);
    try {
      var doc = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: 'a4',
      });

      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const startY = 20;
      const margin = 20;
      const logo = logoRamusBase64;
      let reportType = 'chart';
      let title =
        currMassId === '42'
          ? 'RPM vs Fuel Consumption vs Speed vs ME Running Time'
          : isEMS
          ? 'RPM vs Speed vs AE Running Time'
          : 'RPM vs Fuel Consumption vs Speed';
      let imageHeight: any = 300;
      let reportName = isEMS
        ? 'report-rpm-vs-speed'
        : 'report-rpm-vs-fuel-cons-vs-speed';
      const massName =
        assetObject[currMassId].devices[
          Object.keys(assetObject[currMassId].devices)[0]
        ].massName;

      /* Heading Start */
      doc.setFontSize(12);
      doc.text(`Vessel: ${massName}`, margin, startY + 5);
      doc.setFontSize(10);
      doc.text(
        `Analyzed data: ${moment(dataFilter.range.startAt).format(
          'DD MMM YYYY HH:mm'
        )} to ${moment(dataFilter.range.endAt).format(
          'DD MMM YYYY HH:mm'
        )} GMT +007`,
        margin,
        startY + 17
      );
      doc.addImage(logo, 'PNG', pageWidth - (margin + 70), startY, 60, 20);
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, 50, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(14);
      doc.text(title, pageWidth / 2 - title.length / 2, 70, {
        align: 'center',
      });
      /* Heading End */

      /* Content Start */
      let currImage: any = '';
      if (reportType === 'chart') {
        await htmlToImage
          .toPng(refChart.current)
          .then(function (dataUrl) {
            currImage = dataUrl;
          })
          .catch(function (error) {
            console.error('failed convert html to image', error);
          });
        doc.addImage(
          currImage,
          'JPEG',
          margin,
          80,
          pageWidth - margin * 2,
          imageHeight
        );
      } else {
        autoTable(doc, {
          html: '#summaryTable',
          margin: 20,
          startY: 80,
        });
      }
      /* Content End */

      /* Footer Start */
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, pageHeight - 40, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(11);
      doc.text(
        `Report generated at: ${moment().format('DD MMM YYYY HH:mm:ss')}`,
        margin,
        pageHeight - 20
      );
      /* Footer End */

      doc.save(
        `${(massName || 'report')
          .toLowerCase()
          .replace(/ |-/g, '-')}__${reportName}__${moment(
          dataFilter.range.startAt
        ).format('YYYY-MM-DD_HH_mm')}__${moment(dataFilter.range.endAt).format(
          'YYYY-MM-DD_HH_mm'
        )}.pdf`
      );
    } catch (error) {
      console.error('failed download pdf', error);
    } finally {
      setLoadingDownload(false);
    }
  }

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  const [currDataMaps, setCurrDataMaps] = useState<any>();
  const [loadingDataGps, setLoadingDataGps] = useState<boolean>(false);

  useEffect(() => {
    async function fetchGPSPulse() {
      setLoadingDataGps(true);

      try {
        const resAssets = await AssetSvc.getAssets({
          params: {
            massId: dataFilter.massId,
          },
        });

        let data =
          resAssets.data.data && resAssets.data.data.length > 0
            ? resAssets.data.data.find(
                (item) => item.massId === dataFilter.massId
              )
            : undefined;

        if (data) {
          const assetHaveMassDevice = data.massDevice;

          if (!assetHaveMassDevice) {
            console.log('device that does not have massDevice', data);

            const deviceResult = await DeviceSvc.getDevices({
              params: {
                findField: 'devcMassId',
                findValue: data.massId,
              },
            });

            data = { ...data, massDevice: deviceResult.data.data };
          }

          const found = data.massDevice?.find(
            (item: any) => item.devcType === 'gps'
          );

          const lastDataAssets = await SummarySvc.getLastData('gps', [
            found.devcUniqueId,
          ]);

          const resDataSummary: ISummary | undefined = await fetchSummary({
            aggregatedUnit: 'MINUTE',
            aggregatedLength: 1,
            start: String(moment(dataFilter.range.startAt).valueOf()),
            end: String(moment(dataFilter.range.endAt).valueOf()),
            devcMassId: dataFilter.massId,
            devcType: ['gps'],
          });

          const now = new Date();
          now.setSeconds(0);
          now.setMilliseconds(0);
          const currentUnixDateHour = now.getTime();

          const lastGpsData =
            moment(dataFilter.range.endAt).valueOf() !== currentUnixDateHour
              ? resDataSummary?.gps?.data.pop()
              : lastDataAssets[0];

          const currentTime = moment().valueOf();
          const lastDataTime = lastDataAssets[0].timestamp * 1000;
          const currentDay = moment().startOf('day').valueOf();
          const lastDataDay = moment(lastDataTime).startOf('day').valueOf();

          const dataStatus = currentDay === lastDataDay
              ? currentTime - lastDataTime > 600000
                ? "Waiting Data"
                : "Online"
              : "Offline";

          setCurrDataMaps({
            ...data,
            dataSummary: resDataSummary,
            dataStatus,
            checked: true,
            lastDataGps: lastGpsData,
            firstDataGps: resDataSummary?.gps?.data
              ? resDataSummary?.gps?.data[0]
              : undefined,
            showTrail: true,
          });
        }
      } catch (error) {
        console.error('failed fetch data gps pulse');
      } finally {
        setLoadingDataGps(false);
      }
    }

    if (dataFilter.massId) {
      fetchGPSPulse();

      const intervalId = setInterval(() => {
        fetchGPSPulse();
      }, 1000 * 60 * 2);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dataFilter.massId, dataFilter.range.endAt, dataFilter.range.startAt]);

  useEffect(() => {
    // if (dataFetchedCompRef.current) return;
    // dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : getSource() === 'satellite'
        ? intervalOptions[1].value
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId ? queryMassId : 'initial',
      aggregatedLength: getSource() === 'satellite' ? 1 : 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSource, currentDataTimezone]);
  
  const [operationModeLast, setOperationModeLast] = useState<any>({});
  
  async function getLastOperationMode() {
    try {
      if(!currentAsset?.isHaveOperationMode) return;
      // const lastData = responseSeries.data?.data
      setOperationModeLast(Object.values(currAsset?.operationModeSeries?.data ?? {}).slice(-1)[0]);
    } catch (error) {
      console.error('failed get summary operation', error)
    }
  }

  useEffect(()=>{
    getLastOperationMode();
  }, [dataFilter]);
  
  // useEffect(() => {
  //   if (activeCompanyData) {

  //   }
  // }, [activeCompanyData])

  const dataMaps = useMemo(
    () =>
      assets2
        .filter((item) => item.dataSummary && item.massId === currAsset?.massId)
        .map((item) => ({
          ...item,
          showTrail: true,
        })),
    [assets2, currAsset]
  );

  const dataGPS = useMemo(() => {
    if (currAsset) {
      return [
        {
          label: 'Last Latitude',
          value: latitudeFormat(
            currAsset.lastDataGps?.latitude || 0,
            currAsset.lastDataGps?.longitude || 0
          ),
          iconType: 'marker',
          smallValue: true,
        },
        {
          label: 'Last Longitude',
          value: longitudeFormat(
            currAsset.lastDataGps?.latitude || 0,
            currAsset.lastDataGps?.longitude || 0
          ),
          iconType: 'marker',
          smallValue: true,
        },
        {
          label: 'Time UTC',
          value: moment().utc().format('DD MMM YYYY, HH:mm UTC'),
          iconType: 'time',
        },
        {
          label: 'Time Local',
          value:
            moment().tz('Asia/Jakarta').format('DD MMM YYYY, HH:mm') + ' WIB',
          iconType: 'time',
        },
        {
          label: 'Avg. Speed',
          value: currAsset.dataSummary?.gps?.summary?.averageSpeed
            ? parseFloat(toKnot(currAsset.dataSummary?.gps?.summary?.averageSpeed)) < 0 
              ? -parseFloat(toKnot(currAsset.dataSummary?.gps?.summary?.averageSpeed)) + ' Knot'
              : toKnot(currAsset.dataSummary?.gps?.summary?.averageSpeed) + ' Knot'
            : '-',
          iconType: 'compass',
        },
        {
          label: 'Total Distance',
          value:
            currAsset.lastDataGps && currAsset.lastDataGps?.distance
              ? `${currAsset.lastDataGps?.distance.toFixed(2)} °`
              : '-',
          iconType: 'compass',
        },
      ];
    }
  }, [currAsset]);

  const location = useLocation();

  const compGrcpId = useMemo(() => {
    return activeCompanyData.compGrcpId;
  }, [activeCompanyData]);

  const proconDeviceData = useMemo(()=>{
    const flowmeterData = proconCustomCalculation(currAsset?.dataSummary?.flowmeter?.data, dataFilter.interval);
    return {
      portAverageFlow: flowmeterData.portAverageFlow,
      starboardAverageFlow: flowmeterData.starboardAverageFlow,
      portTotalFuelCons: flowmeterData.portTotalFuelCons,
      starboardTotalFuelCons: flowmeterData.starboardTotalFuelCons
    }
  },[currAsset]);

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <FilterDashboard
        setURLParams={handleSetURLParams}
        searchParams={searchParams}
      />
      {isLoadingFilter || isLoadingAssetSummary ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : isDataExist ? (
        <>
          <ContentWrapper 
            gridTemplateRows={
            (currAsset.isHaveOperationMode && !currAsset.isThreeEngine)
              ? '420px 770px 115px 570px' // 2 engine dengan operation mode
              : currAsset.isThreeEngine
              ? '420px 385px 570px' // 3 engine tanpa operation mode
              : currAsset.isHaveOperationMode
              ? '420px 1155px 115px 570px' // 3 engine dengan operation mode
              : '420px 385px 570px' // default
            }
          >
            <MapCard>
              {currDataMaps &&
              currDataMaps.lastDataGps &&
              currDataMaps.firstDataGps ? (
                <BaseMap
                  showSummary={false}
                  zoomOnScrollWheel={false}
                  isFullPage={false}
                  dataMaps={[currDataMaps]}
                />
              ) : isLoadingAsset || loadingDataGps ? (
                <ContainerEmptyData>
                  <Spin />
                  <h1>Loading data map...</h1>
                </ContainerEmptyData>
              ) : (
                <ContainerEmptyData>
                  <InfoCircleOutlined size={140} />
                  <div>Data gps not found</div>
                </ContainerEmptyData>
              )}
            </MapCard>

            <ShipInfoCard>
              {dataGPS && dataGPS.length > 0 ? (
                dataGPS.map((item, key) => (
                  <CardInfo
                    style={
                      key === 2
                        ? { gridColumn: '1/3' }
                        : key === 3
                        ? { gridColumn: '1/3' }
                        : {}
                    }
                    key={key}
                    label={item.label}
                    value={item.value}
                    iconType={item.iconType}
                    unit={undefined}
                    smallValue={item.smallValue}
                  />
                ))
              ) : (
                <ContainerEmptyData isGrid>
                  <InfoCircleOutlined size={140} />
                  <div>Data gps not found</div>
                </ContainerEmptyData>
              )}
            </ShipInfoCard>

            {/* Gauge RPM Port */}
            <GaugeWrapper isHaveOperationMode={currAsset.isHaveOperationMode ?? false} isThreeEngine={currAsset.isThreeEngine ?? false}>
              <GaugeCard
                title='Port'
                isSingleEngine={isSingleEngine}
                subtitle={lastUpdatedRPM(
                  isSingleEngine ? 'MAINENGINE' : 'PORT',
                  isSingleEngine
                )}
                rpm={
                  currAsset.isHaveOperationMode
                  ? getLastRpmData('PORT')?.rpm ?? 0 
                  : formatDataRPM('PORT', isSingleEngine)
                }
                isEMS={currAsset.isEMS ?? false}
                currentFlow={'-'}
                averageFlow={currAsset.massId === '62' 
                  ? proconDeviceData.portAverageFlow
                  : !currAsset.dataSummary?.flowmeter &&
                    !currAsset.dataSummary?.flowmeter?.summary &&
                    currAsset.dataSummary?.flowmeter?.data.length === 0
                      ? '0'
                      : fixedValueString(currAsset.dataSummary?.flowmeter?.summary.portAverageFlow) || '0'
                }
                fuelConsumption={currAsset.massId === '62' 
                  ? proconDeviceData.portTotalFuelCons
                  : !currAsset.dataSummary?.flowmeter &&
                    !currAsset.dataSummary?.flowmeter?.summary &&
                    currAsset.dataSummary?.flowmeter?.data.length === 0
                      ? '0'
                      : fixedValueString(currAsset.dataSummary?.flowmeter?.summary.portEngineCons ) || '0'
                }
                isHaveOperationMode={currAsset.isHaveOperationMode ?? false}
                lastOperationData={operationModeLast?.PORT}
              />

              {currAsset.isThreeEngine && (
                <GaugeCard
                  title='Center'
                  subtitle={lastUpdatedRPM('CENTER')}
                  rpm={currAsset.isHaveOperationMode ? getLastRpmData('CENTER')?.rpm ?? 0 : formatDataRPM('CENTER')}
                  isEMS={currAsset.isEMS ?? false}
                  currentFlow={'-'}
                  averageFlow={'-'}
                  fuelConsumption={'-'}
                  isHaveOperationMode={currAsset.isHaveOperationMode ?? false}
                  lastOperationData={operationModeLast?.CENTER}
                />
              )}

              {/* gauge rpm starboard */}
              {!isSingleEngine && (
                <GaugeCard
                  title='Starboard'
                  subtitle={lastUpdatedRPM('STARBOARD')}
                  rpm={currAsset.isHaveOperationMode ? getLastRpmData('STARBOARD')?.rpm ?? 0 : formatDataRPM('STARBOARD')}
                  isEMS={currAsset.isEMS ?? false}
                  currentFlow={'-'}
                  averageFlow={currAsset.massId === '62' 
                    ? proconDeviceData.starboardAverageFlow 
                    : !currAsset.dataSummary?.flowmeter &&
                      !currAsset.dataSummary?.flowmeter?.summary &&
                      currAsset.dataSummary?.flowmeter?.data.length === 0
                        ? '0'
                        : fixedValueString(currAsset.dataSummary?.flowmeter?.summary.starboardAverageFlow) || '0'
                  }
                  fuelConsumption={currAsset.massId === '62' 
                    ? proconDeviceData.starboardTotalFuelCons
                    : !currAsset.dataSummary?.flowmeter &&
                      !currAsset.dataSummary?.flowmeter?.summary &&
                      currAsset.dataSummary?.flowmeter?.data.length === 0
                        ? '0'
                        : fixedValueString(currAsset.dataSummary?.flowmeter?.summary.starboardEngineCons) || '0'
                  }
                  isHaveOperationMode={currAsset.isHaveOperationMode ?? false}
                  lastOperationData={operationModeLast?.STARBOARD}
                />
              )}
            </GaugeWrapper>

            {currAsset.isHaveOperationMode && (
              <OPWrapper>
                <OPCard
                  title='Port'
                  subtitle={operationModeLast?.PORT?.timestamp ? 'Last data: ' + moment.utc(operationModeLast?.PORT?.timestamp).local().format('DD MMM YYYY HH:mm') : 'No Data'}
                  operationModeData={currAsset?.operationModeSummary?.engine?.PORT}
                  operationModeTemplate={currAsset.opsModeTemplate}
                />
                {currAsset.isThreeEngine && (
                  <OPCard
                    title='Center'
                    subtitle={operationModeLast?.CENTER?.timestamp ? 'Last data: ' + moment.utc(operationModeLast?.CENTER?.timestamp).local().format('DD MMM YYYY HH:mm') : 'No Data'}
                    operationModeData={currAsset?.operationModeSummary?.engine?.CENTER}
                    operationModeTemplate={currAsset.opsModeTemplate}
                  />
                )}
                <OPCard
                  title='Starboard'
                  subtitle={operationModeLast?.STARBOARD?.timestamp ? 'Last data: '+ moment.utc(operationModeLast?.STARBOARD?.timestamp).local().format('DD MMM YYYY HH:mm') : 'No Data'}
                  operationModeData={currAsset?.operationModeSummary?.engine?.STARBOARD}
                  operationModeTemplate={currAsset.opsModeTemplate}
                />
              </OPWrapper>
            )}

            <AEWrapper isHaveOperationMode={currAsset.isHaveOperationMode ?? false}>
              {currAsset.dataSummary?.ae &&
                currAsset.dataSummary?.ae?.summary &&
                currAsset.dataSummary?.ae?.data.length > 0 &&
                (activeCompany?.compId === '39' ? (
                  <Carousel style={{ height: '100%' }}>
                    <AuxiliaryEngineCard isHaveOperationMode={currAsset.isHaveOperationMode ?? false}>
                      <AECardItem
                        type='AE1'
                        fuelCons={
                          currAsset.dataSummary.ae.summary.fuelConsumption.AE1
                        }
                        runningHour={
                          currAsset.dataSummary.ae.summary.runningSeconds.AE1
                        }
                        lastUpdated={currAsset.dataSummary.ae.data}
                      />
                      <AECardItem
                        type='AE2'
                        fuelCons={
                          currAsset.dataSummary.ae.summary.fuelConsumption.AE2
                        }
                        runningHour={
                          currAsset.dataSummary.ae.summary.runningSeconds.AE2
                        }
                        lastUpdated={currAsset.dataSummary.ae.data}
                      />
                      <AECardItem
                        type='AE3'
                        fuelCons={
                          currAsset.dataSummary.ae.summary.fuelConsumption.AE3
                        }
                        runningHour={
                          currAsset.dataSummary.ae.summary.runningSeconds.AE3
                        }
                        lastUpdated={currAsset.dataSummary.ae.data}
                      />
                    </AuxiliaryEngineCard>
                    <AuxiliaryEngineCard isHaveOperationMode={currAsset.isHaveOperationMode ?? false}>
                      <AECardItem
                        type='AE4'
                        fuelCons={
                          currAsset.dataSummary.ae.summary.fuelConsumption.AE4
                        }
                        runningHour={
                          currAsset.dataSummary.ae.summary.runningSeconds.AE4
                        }
                        lastUpdated={currAsset.dataSummary.ae.data}
                      />
                    </AuxiliaryEngineCard>
                  </Carousel>
                ) : (
                  <AuxiliaryEngineCard isHaveOperationMode={currAsset.isHaveOperationMode ?? false}>
                    <AECardItem
                      type='AE1'
                      fuelCons={
                        currAsset.dataSummary.ae.summary.fuelConsumption.AE1
                      }
                      runningHour={
                        currAsset.dataSummary.ae.summary.runningSeconds.AE1
                      }
                      lastUpdated={currAsset.dataSummary.ae.data}
                    />
                    <AECardItem
                      type='AE2'
                      fuelCons={
                        currAsset.dataSummary.ae.summary.fuelConsumption.AE2
                      }
                      runningHour={
                        currAsset.dataSummary.ae.summary.runningSeconds.AE2
                      }
                      lastUpdated={currAsset.dataSummary.ae.data}
                    />
                    <AECardItem
                      type='AE3'
                      fuelCons={
                        currAsset.dataSummary.ae.summary.fuelConsumption.AE3
                      }
                      runningHour={
                        currAsset.dataSummary.ae.summary.runningSeconds.AE3
                      }
                      lastUpdated={currAsset.dataSummary.ae.data}
                    />
                  </AuxiliaryEngineCard>
                ))}
            </AEWrapper>

            {!isLoadingFilter && (
              <MultiChartCard style={{ height: 'auto' }}>
                <HChartRpmFuelSpeedv2
                  isDashboard
                  ref={refChart}
                  compId={currAsset.massCompId}
                  massId={currAsset.massId}
                  isEMS={currAsset.isEMS}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                  columns={generateHourArray(
                    dataFilter.range.startAt,
                    dataFilter.range.endAt
                  )}
                  isLoading={loadingDownload}
                  onClickDownload={handleDownloadPDF}
                />
              </MultiChartCard>
            )}
          </ContentWrapper>
        </>
      ) : (
        <ContainerLoading>
          <LoadingWrapper>
            <InfoCircleOutlined style={{ fontSize: 30 }} />
            <h3>
              <b>Tidak ada data</b>
            </h3>
            <div>Data for GPS, RPM, Flowmeter and AE are empty</div>
          </LoadingWrapper>
        </ContainerLoading>
      )}
    </React.Fragment>
  );
};
interface IAuxiliaryEngineCard {
  isHaveOperationMode: boolean;
}

const MultiChartCard = styled.div`
  grid-column: 1/4;
  border-radius: 8px;
  background-color: white;
`;

const AuxiliaryEngineCard = styled.div<IAuxiliaryEngineCard>`
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  gap: 10px;
  grid-template-rows: ${(props) =>
    props.isHaveOperationMode ? 'none' : 'repeat(3, 1fr)'};
  grid-template-columns: ${(props) =>
    props.isHaveOperationMode ? 'repeat(3, 1fr)' : 'none'};
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.grayDarken_Dark};
  }
  > div {
    background-color: white;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    min-height: 115px;
    span {
      display: block;
      color: ${(props) => props.theme.colors.grayDarken_Default};
      font-size: 12px;
    }
    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

interface IAECardItem {
  type: 'AE1' | 'AE2' | 'AE3' | 'AE4';
  fuelCons: any;
  runningHour: any;
  lastUpdated: IAEData[];
}

const AECardItem: React.FC<IAECardItem> = (props) => {
  const lastUpdate = useMemo(() => {
    const lastData =
      props.lastUpdated[props.lastUpdated.length - 1] &&
      props.lastUpdated[props.lastUpdated.length - 1][props.type] &&
      props.lastUpdated[props.lastUpdated.length - 1][props.type].timestamp
        ? props.lastUpdated[props.lastUpdated.length - 1][props.type].timestamp
        : undefined;
    return lastData
      ? moment(lastData * 1000).format('DD MMM YYYY, HH:mm')
      : 'Data Empty';
  }, [props.lastUpdated, props.type]);

  const title = useMemo(() => {
    let result = '-';
    switch (props.type) {
      case 'AE1':
        result = 'AE1';
        break;
      case 'AE2':
        result = 'AE2';
        break;
      case 'AE3':
        result = 'AE3';
        break;
      case 'AE4':
        result = 'AE4';
        break;

      default:
        break;
    }

    return result;
  }, [props.type]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid lightgray',
          paddingBottom: 5,
        }}
      >
        <h3>{title}</h3>
        <span>Last data: {lastUpdate}</span>
      </div>
      <div>
        <div>
          <span>Fuel Consumption</span>
          <Flex>
            <GaugeValue>{fixedValueString(props.fuelCons)}</GaugeValue>
            <GaugeUnit>L</GaugeUnit>
          </Flex>
        </div>
        <div>
          <span>Running Hour</span>
          <Flex>
            <GaugeValue>{toHHMMSS(props.runningHour)}</GaugeValue>
            {/* <GaugeUnit>hour</GaugeUnit> */}
          </Flex>
        </div>
      </div>
    </div>
  );
};

interface IGaugeCard extends ITitleCard {
  currentFlow: string;
  averageFlow: string;
  fuelConsumption: string;
  rpm: number;
  isEMS: boolean;
  isSingleEngine?: boolean;
  isHaveOperationMode: boolean;
  lastOperationData?: any;
}

const GaugeCard: React.FC<IGaugeCard> = (props) => {
  const newOptionGauge = {
    ...optionsGauge,
    series: [
      {
        ...optionsGauge.series[0],
        data: [parseInt(props.rpm.toFixed(0))],
      },
    ],
  };
  return (
    <WrapperGaugeChart isSingleEngine={props.isSingleEngine}>
      <TitleCard
        showTitleBorder
        title={props.title}
        subtitle={props.subtitle}
      />
      <HighchartsReact highcharts={Highcharts} options={newOptionGauge} />
      {!props.isEMS ? (
        <SummaryGaugeChart>
          <FlexColumn>
            <GaugeTitleItem>Current Flow</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.currentFlow}</GaugeValue>
              <GaugeUnit>L/h</GaugeUnit>
            </Flex>
          </FlexColumn>
          <FlexColumn>
            <GaugeTitleItem>Average Flow</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.averageFlow}</GaugeValue>
              <GaugeUnit>L/h</GaugeUnit>
            </Flex>
          </FlexColumn>
          <FlexColumn>
            <GaugeTitleItem>Fuel Cons.</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.fuelConsumption}</GaugeValue>
              <GaugeUnit>L</GaugeUnit>
            </Flex>
          </FlexColumn>
        </SummaryGaugeChart>
      ) : props.isHaveOperationMode ? (
        <SummaryGaugeChart>
          <FlexColumn>
            <GaugeTitleItem>Operation Mode</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.lastOperationData?.mode ?? '-'}</GaugeValue>
            </Flex>
          </FlexColumn>
          <FlexColumn>
            <GaugeTitleItem>Fuel Cons.</GaugeTitleItem>
            <Flex>
              <GaugeValue>{Number(props.lastOperationData?.value).toFixed(2)}</GaugeValue>
              <GaugeUnit>L/m</GaugeUnit>
            </Flex>
          </FlexColumn>
        </SummaryGaugeChart>
      ) : (
        <FlowmeterEmpty>
          <span>No flowmeter data</span>
        </FlowmeterEmpty>
      )}
    </WrapperGaugeChart>
  );
};

interface ITitleCard {
  title: string;
  subtitle?: string;
  showTitleBorder?: boolean;
}

export const TitleCard: React.FC<ITitleCard> = (props) => {
  return (
    <div
      style={{
        padding: '10px 15px',
        marginBottom: 10,
        width: '100%',
        borderBottom: props.showTitleBorder ? '1px solid lightgray' : 'none',
      }}
    >
      <Title>{props.title}</Title>
      {props.subtitle && <div style={{ opacity: 0.5 }}>{props.subtitle}</div>}
    </div>
  );
};

interface IContainerEmptyData {
  isGrid?: boolean;
}

interface IContentWrapper {
  gridTemplateRows: string;
}

const ContainerEmptyData = styled.div<IContainerEmptyData>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  grid-column: ${(props) => (props.isGrid ? '1/3' : 'auto')};
  grid-row: ${(props) => (props.isGrid ? '1/5' : 'auto')};
`;

const FlowmeterEmpty = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Light};
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    color: ${(props) => props.theme.colors.grayDarken_Dark};
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grayDarken_Dark};
`;

const SummaryGaugeChart = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px 20px;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GaugeTitleItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;

const GaugeValue = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const GaugeUnit = styled.div`
  font-size: 16px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;

const ContentWrapper = styled.div<IContentWrapper>`
  display: grid;
  gap: 20px 20px;
  width: 100%;
  padding: 20px 20px;
  background: ${(props) => props.theme.colors.grayLighten_Default};
  grid-template-columns: repeat(2, 1fr) 380px;
  grid-template-rows: ${(props) => props.gridTemplateRows};
`;

const MapCard = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  grid-column: 1/3;
  grid-row: 1;
`;

const ShipInfoCard = styled.div`
  grid-column: 3/4;
  grid-row: 1;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
`;

interface IGaugeWrapper {
  isHaveOperationMode: boolean;
  isThreeEngine: boolean;
}

const GaugeWrapper = styled.div<IGaugeWrapper>`
  grid-column: ${(props) =>
    props.isHaveOperationMode ? '1/2' : '1/3'};
  max-width: ${(props) =>
    props.isHaveOperationMode ? '100%' : 'none'};
  display: grid;
  gap: ${(props) =>
    props.isHaveOperationMode ? 'none' : '20px'};
  grid-template-columns: ${(props) =>
    props.isHaveOperationMode ? 'none' : props.isThreeEngine ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'};
`;

const OPWrapper = styled.div`
  grid-column: 2/4;
  height: 570px;
`;

const OPCardWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  height: 380px;
`;

interface OperationModeData {
  [key: string]: {
    value: number;
    runningSeconds: number;
    maxRpm: string;
  };
}

interface IOPCard {
  title: string;
  subtitle: string;
  operationModeData?: ModeDataSeries;
  operationModeTemplate: IOperationDetail[] | undefined;
}

const OPCard: React.FC<IOPCard> = (props) => {

  return (
    <OPCardWrapper>
      <TitleCard
        showTitleBorder
        title={props.title}
        subtitle={props.subtitle}
      />
      {props.operationModeData && props.operationModeTemplate ? (
        <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
        <table id='summaryTable' className='custom-table'>
          <thead>
            <tr>
              <th>Operating Mode</th>
              <th>Running Time</th>
              <th>Fuel Cons (Liter)</th>
            </tr>
          </thead>
          <tbody>
            {props.operationModeTemplate.length > 0 && props.operationModeTemplate.map((elm, idx) => {
              const foundOPData = props.operationModeData ? props.operationModeData[elm.mrodName] : undefined;
              if(foundOPData){
                return (<tr key={idx}>
                  <td>{elm.mrodName}</td>
                  <td>{toHHMMSS(foundOPData.runningSeconds)}</td>
                  <td>{foundOPData.value.toFixed(2)}</td>
                </tr>)
              }else{
                return (<tr key={idx}>
                  <td>{elm.mrodName}</td>
                  <td>{toHHMMSS(0)}</td>
                  <td>{0}</td>
                </tr>)
              }
            })}
          </tbody>
        </table>
      </div>
      ) : (<p>No Operation Mode Data</p>)}
      
    </OPCardWrapper>
  );
};

interface IAEWrapper {
  isHaveOperationMode: boolean;
}

const AEWrapper = styled.div<IAEWrapper>`
  grid-column: ${(props) =>
    props.isHaveOperationMode ? '1/4' : '3/4'};
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

interface IWrapperGaugeChart {
  isSingleEngine?: boolean;
}

const WrapperGaugeChart = styled.div<IWrapperGaugeChart>`
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  grid-column: ${(props) => (props.isSingleEngine ? '1/3' : 'auto')};
  margin-bottom: 10px;
  height: 380px;
`;

const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

export default Dashboard;
