import { Button, Checkbox, Col, Popover, Row } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useState } from "react";

const PopupDownload: React.FC<{
  options: { key: string; label: string }[];
  loading: boolean;
  onDownload: (selectedItems: string[]) => void;
  onDownloadXLSX?: () => void;
  isReportLog?: boolean;
}> = (props) => {
  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);

  function handleChange(checkedValues: CheckboxValueType[]) {
    setSelectedItems(checkedValues);
  }

  function handleDownload(all?: boolean) {
    if (!all) {
      props.onDownload(selectedItems as string[]);
    } else {
      props.onDownload(props.options.map((item) => item.key));
    }
  }

  const CheckboxRender = () => {
    return (
      <>
        <div style={{ color: "#919191" }}>Choose report to downloaded</div>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleChange}
          value={selectedItems}
        >
          {props.options.map((item, idx) => (
            <Checkbox
              style={{
                margin: 0,
                width: "100%",
                padding: "5px 0",
                borderBottom: "1px solid #f1f1f1",
              }}
              key={idx}
              value={item.key}
            >
              {item.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ flex: 1 }}
            onClick={() => handleDownload()}
            disabled={props.loading}
            loading={props.loading}
            color="primary"
            size="small"
          >
            Download {selectedItems.length > 0 && `(${selectedItems.length})`}
          </Button>
          <div style={{ width: 5 }} />
          <Button
            style={{ flex: 1 }}
            onClick={() => handleDownload(true)}
            disabled={props.loading}
            loading={props.loading}
            color="primary"
            size="small"
          >
            Download All
          </Button>
        </div>
        {props.isReportLog && (
          <div
            style={{
              marginTop: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => props.onDownloadXLSX && props.onDownloadXLSX()}
              disabled={props.loading}
              loading={props.loading}
              color="primary"
              size="small"
              style={{ flex: 1 }}
            >
              Download report log as XLSX
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <Popover
      content={<CheckboxRender />}
      trigger="hover"
      overlayInnerStyle={{
        width: 320,
      }}
      placement="bottomRight"
    >
      <Button
        disabled={props.loading}
        loading={props.loading}
        style={{
          background: "#55C95A",
          color: "white",
          margin: "0 0 10px",
        }}
        size="small"
      >
        Download
      </Button>
    </Popover>
  );
};

export default PopupDownload;
