import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  generateArrays,
  generateDataTable,
  hChartConfig,
  hColors,
} from '../../helpers/chart';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import {
  fillNullValues,
  generateSeriesDataZone,
  roundValue,
  zoneChartType,
} from '../../helpers/dateUtil';
import { IAEData, ISummary } from '../../types/summary.type';
import styled from 'styled-components';
import { subtitleDottedLine } from '../../helpers/constant';
import useCompany from '../../hooks/useCompany'
highchartsMore(Highcharts);

interface IProps {
  data?: ISummary;
  isDailyReport?: boolean;
  columns: number[];
}

const HChartAEFuelCons = React.forwardRef<any, IProps>(
  ({ data, isDailyReport, columns }, ref) => {
    const {activeCompany} = useCompany();
    const [dataChart, setDataChart] = useState<any>();
    const [summary, setSummary] = useState({
      avg: 0,
    });
    const { dataFilter } = useFilter();

    const generateYAxis = () => {
      let yAxis: any = [];
      yAxis = [
        {
          labels: {
            format: '{value}',
            style: {
              color: hColors.black,
            },
          },
          title: {
            text: 'Liter/hour',
            style: {
              color: hColors.black,
            },
          },
          lineColor: hColors.black,
          lineWidth: 1,
          tickColor: hColors.black,
          tickWidth: 1,
          tickLength: 5,
        },
      ];

      return yAxis;
    };

    const dataTable = useMemo(() => {
      if (data) {
        return generateDataTable(data);
      }
    }, [data]);

    // console.log('CHART AE FUEL CONS - raw data: ', data);
    // console.log('CHART AE FUEL CONS - data chart:', dataChart);

    useEffect(() => {
      if (data) {
        const createDataSeries = () => {
          let currChartOption: any = {
            ...hChartConfig,
            plotOptions: {
              ...hChartConfig.plotOptions,
              column: {
                stacking: 'normal',
                dataLabels: {
                  enabled: true,
                },
              },
              xAxis: [
                {
                  categories: [],
                  crosshair: true,
                },
              ],
              yAxis: generateYAxis(),
              series: [],
            },
          };

          const categories = data.ae?.data.map((ae: IAEData) => {
            let timestamp = 0;
            if (ae.AE1) {
              timestamp = ae.AE1.timestamp;
            } else if (ae.AE2) {
              timestamp = ae.AE2.timestamp;
            } else if (ae.AE3) {
              timestamp = ae.AE3.timestamp;
            } else if (ae.AE4) {
              timestamp = ae.AE4.timestamp;
            }
            return moment(timestamp * 1000).format('DD/MM/YY HH:mm');
          });

          const dataSeries: any = [];
          let avgAEFuelCons = 0;

          Object.keys(data).forEach((key) => {
            if (
              key === 'ae' &&
              data['ae'] &&
              data['ae'].data &&
              data['ae'].data.length > 0
            ) {
              const pushedData:any = {}
              dataSeries.push(
                {
                  name: 'AE1 Running Hour.',
                  type: 'column',
                  yAxis: 0,
                  data: data['ae'].data.map((ae: IAEData) =>
                    ae.AE1 ? ae.AE1.fuelConsumption : 0
                  ),
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.purple,
                  zIndex: 1,
                },
                {
                  name: 'AE2 Running Hour.',
                  type: 'column',
                  yAxis: 0,
                  data: data['ae'].data.map((ae: IAEData) =>
                    ae.AE2 ? ae.AE2.fuelConsumption : 0
                  ),
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.teal,
                  zIndex: 1,
                },
                {
                  name: 'AE3 Running Hour.',
                  type: 'column',
                  yAxis: 0,
                  data: data['ae'].data.map((ae: IAEData) =>
                    ae.AE3 ? ae.AE3.fuelConsumption : 0
                  ),
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.yellow,
                  zIndex: 1,
                },

              );
              if(activeCompany?.compId === '39'){
                dataSeries.push({
                  name: 'AE4 Running Hour.',
                  type: 'column',
                  yAxis: 0,
                  data: data['ae'].data.map((ae: IAEData) =>
                    ae.AE4 ? ae.AE4.fuelConsumption : 0
                  ),
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.darkBlue,
                  zIndex: 1,
                })
              }

              let existingAEValue = 0;
              for (let i = 0; i < data['ae'].data.length; i++) {
                const ae = data['ae'].data[i];
                if (
                  ae.AE1?.fuelConsumption > 0 ||
                  ae.AE2?.fuelConsumption > 0 ||
                  ae.AE3?.fuelConsumption > 0 ||
                  ae.AE4?.fuelConsumption > 0
                ) {
                  existingAEValue++;
                }
              }

              avgAEFuelCons =
                (data['ae'].summary?.fuelConsumption.AE1 +
                  data['ae'].summary?.fuelConsumption.AE2 +
                  data['ae'].summary?.fuelConsumption.AE3 +
                  data['ae'].summary?.fuelConsumption.AE4) /
                existingAEValue;

              setSummary({
                avg: avgAEFuelCons,
              });

              dataSeries.push({
                name: 'Avg. Fuel Cons.',
                type: 'line',
                yAxis: 0,
                marker: {
                  enabled: false,
                },
                data: generateArrays(avgAEFuelCons, data['ae'].data.length),
                tooltip: {
                  valueSuffix: ' Liter',
                },
                color: hColors.red,
                zIndex: 5,
              });
            }
          });

          const newDataSeries = dataSeries.map((serie: any) => {
            const data = [...serie.data].map((value: number | undefined) => {
              return typeof value === 'undefined'
                ? null
                : (value && typeof value === 'number') || value === 0
                ? Number(value.toFixed(2))
                : null;
            });

            const newData = fillNullValues(data);

            return {
              ...serie,
              data: newData,
              zoneAxis: 'x',
              zones: zoneChartType.includes(serie.type)
                ? generateSeriesDataZone({
                    data,
                    color: serie.color,
                    type: serie.type,
                  })
                : [],
            };
          });

          currChartOption = {
            ...currChartOption,
            xAxis: [
              {
                categories,
                crosshair: true,
              },
            ],
            series: newDataSeries,
          };

          setDataChart(currChartOption);
        };

        createDataSeries();
      }
    }, [data]);

    return (
      <>
        {!isDailyReport && (
          <TitleCard
            title='AE Fuel Consumption'
            subtitle={!isDailyReport && subtitleDottedLine}
          />
        )}
        <div ref={ref} style={{ height: '100%' }}>
          {dataChart && dataChart.series.length === 0 ? (
            <EmptyChart>
              <h3>Empty Data</h3>
              <p>Data series empty</p>
            </EmptyChart>
          ) : (
            <HighchartsReact
              containerProps={{
                style: {
                  height: isDailyReport ? '70%' : '90%',
                },
              }}
              highcharts={Highcharts}
              options={{
                ...dataChart,
                chart: {
                  zoomType: 'xy',
                },
              }}
              allowChartUpdate={true}
              immutable={true}
            />
          )}
          {isDailyReport &&
            dataFilter.interval === 'HOUR' &&
            dataTable &&
            data &&
            data.ae &&
            data.ae.data &&
            data.ae.data.length > 0 && (
              <table
                className='custom-table-daily-report'
                style={{
                  width: isDailyReport ? '100%' : '90%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                <tr>
                  <th>Hour</th>
                  {columns.map((item) => (
                    <td key={item}>{item}</td>
                  ))}
                </tr>
                <tr>
                  <th>AE 1 Fuel Cons.</th>
                  {dataTable.ae.data?.map((item: IAEData, idx: number) => (
                    <td key={`ae1-${idx}`}>
                      {item.AE1
                        ? roundValue(item.AE1.fuelConsumption || 0)
                        : 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th>AE 2 Fuel Cons.</th>
                  {dataTable.ae.data?.map((item: IAEData, idx: number) => (
                    <td key={`ae2-${idx}`}>
                      {item.AE2
                        ? roundValue(item.AE2.fuelConsumption || 0)
                        : 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th>AE 3 Fuel Cons.</th>
                  {dataTable.ae.data?.map((item: IAEData, idx: number) => (
                    <td key={`ae3-${idx}`}>
                      {item.AE3
                        ? roundValue(item.AE3.fuelConsumption || 0)
                        : 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th>AE 4 Fuel Cons.</th>
                  {dataTable.ae.data?.map((item: IAEData, idx: number) => (
                    <td key={`ae4-${idx}`}>
                      {item.AE4
                        ? roundValue(item.AE4.fuelConsumption || 0)
                        : 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th>Avg. Fuel Cons.</th>
                  <td key='avg-fuel' colSpan={24}>
                    {roundValue(summary.avg || 0)}
                  </td>
                </tr>
                </tbody>
              </table>
            )}
        </div>
      </>
    );
  }
);

export const EmptyChart = styled.div`
    margin: 0 auto;
    text-align: center;

    h3 {
        margin-bottom: 0;
    }
`;

export default HChartAEFuelCons;
