import styled from "styled-components";

interface Props {
  dataStatus: "Online" | "Offline" | "Waiting Data";
  customText?: string;
  style?: React.CSSProperties; 
}

const StatusTag: React.FC<Props> = (props) => {
  return (
    <CustomTag dataStatus={props.dataStatus} style={props.style}>
      {props.customText ? props.customText : props.dataStatus}
    </CustomTag>
  );
};

const CustomTag = styled.span<Props>`
  border-radius: 5px;
  padding: 0 4px;
  background-color: ${(props) => {
    switch (props.dataStatus) {
      case "Online":
        return props.theme.colors.success_Light;
      case "Waiting Data":
        return "#D9A413";
      case "Offline":
      default:
        return props.theme.colors.danger_Light;
    }
  }};
  color: ${(props) => {
    switch (props.dataStatus) {
      case "Online":
        return props.theme.colors.success_Dark;
      case "Waiting Data":
        return props.theme.colors.warning_Light;
      case "Offline":
      default:
        return props.theme.colors.danger_Default;
    }
  }};
  font-size: ${(props) => props.theme.fontSize.body2};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export default StatusTag;
