import axios from 'axios';
import { getToken, getTokenAuthentication, removeToken, removeTokenAuthentication } from './auth';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.timeout = 300000;

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const httpRequest = axios.create();
export const httpRequestPDF = axios.create();

httpRequest.interceptors.request.use(
  async (config: any) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authentication'] = getTokenAuthentication();
    return config;
  },
  (error) => {
    console.error('httpRequest: Error interceptor request:::', error.response);
    return Promise.reject(error);
  }
);

httpRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (
        error.response.status === 403 ||
        error.response.data.code === 'err_unauthorized'
      ) {
        removeToken();
        removeTokenAuthentication();
        window.location.href = '/';
      } else if (
        error.response.status === 404 &&
        error.response.data.status &&
        (error.response.data.status.errorCode === 411 || error.response.data.status.errorCode === 402 ||
          error.response.data.status.errorCode === 403)
      ) {
        removeToken();
        removeTokenAuthentication();
        localStorage.removeItem('app');
        window.location.href = '/';
      }
      console.error(
        'httpRequest: Error interceptor response:::',
        error.response
      );
      return Promise.reject(error.response);
    } else {
      console.error(error);
    }
  }
);

httpRequestPDF.interceptors.request.use(
  async (config: any) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authentication'] = getTokenAuthentication();
    config.headers['Accept'] = 'application/pdf';
    config.responseType = 'arraybuffer';
    return config;
  },
  (error) => {
    console.error(
      'httpRequestPDF: Error interceptor request:::',
      error.response
    );
    return Promise.reject(error);
  }
);
