import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import { ReactComponent as Homesvg } from '../assets/images/Home2.svg';
import { ReactComponent as Dashboardsvg } from '../assets/images/Dashboard3.svg';
import { ReactComponent as Reportsvg } from '../assets/images/File.svg';
import { ReactComponent as Groupsvg } from '../assets/images/Apps.svg';
import { ReactComponent as Companysvg } from '../assets/images/Building.svg';
import { ReactComponent as Shipsvg } from '../assets/images/kapal.svg';
import { ReactComponent as Devicesvg } from '../assets/images/Mobile.svg';
import { ReactComponent as Usersvg } from '../assets/images/User Group.svg';
import { ReactComponent as Rolesvg } from '../assets/images/User Check.svg';
import { ReactComponent as EmailSvg } from '../assets/images/Email.svg';
import { ReactComponent as OperationModeSvg } from '../assets/images/ships.svg';

import Icon from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useAsset from '../hooks/useAsset';
import useAuth from '../hooks/useAuth';
import useMapConfig from '../hooks/useMapConfig';
import useFilter from '../hooks/useFilter';
import { singleEngineAssets } from '../helpers/constant';
import useCompany from '../hooks/useCompany';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const { selectedApp } = useMapConfig();
  const { dataFilter, setDataFilter } = useFilter();
  const { assets2, isLoadingAssetSummary } = useAsset();
  const { activeCompany } = useCompany();
  const { access, handlePermission } = useAuth();

  const currAsset = useMemo(() => {
    const res = assets2.find((item) => item.massId === dataFilter.massId);
    if (res) {
      return {
        isEMS: res.isEMS,
        isSingleEngine: res.isSingleEngine,
        isHaveOperationMode: res.isHaveOperationMode
      };
    }

    return;
  }, [dataFilter, assets2]);

  useEffect(() => {
    /**
     * [Temporary Solution]
     * Goal:
     * 1. Filter sidebar untuk jenis kapal dengan satu engine
     *
     * Note:
     * 1. Jika satu asset saja dari keseluruhan asset termasuk ke single engine maka akan
     * validasi ini akan berlaku.
     */
    const singleEngineFound = assets2.find((item) =>
      singleEngineAssets.includes(item.massId)
    );
    if (
      path === '/home' &&
      assets2.length > 0 &&
      !dataFilter.massId &&
      singleEngineFound
    ) {
      setDataFilter((current) => ({
        ...current,
        massId: singleEngineFound.massId,
      }));
    }
  }, [path, dataFilter, assets2]);

  const filterPermission = (items: any[]) => {
    let newList: any = [];
    for (const item of items) {
      if (!item.children || item.children.length === 0) {
        newList.push(item);
      } else {
        let newChildren: any = [];
        for (const children of item.children) {
          if (children.module) {
            const hasPermission = handlePermission(children.module, 'read');
            if (hasPermission) {
              newChildren.push(children);
            }
          } else {
            newChildren.push(children);
          }
        }
        newList.push({ ...item, children: newChildren });
      }
    }
    setItems(newList.filter((item: any) => item.children.length > 0));
  };

  const reportChildren = useMemo(() => {
    return [
      {
        key: '/report-summary',
        label: 'Summary',
      },
      {
        key: '/report-vessel-tracking',
        label: 'Vessel Tracking',
      },
      {
        key: currAsset?.isEMS
          ? '/report-chart/rpm-vs-speed-vs-ae'
          : '/report-chart/rpm-vs-fuel-cons-vs-speed-vs-ae',
        label: currAsset?.isEMS
          ? 'RPM Vs Speed Vs AE Running Time'
          : 'RPM Vs Fuel Cons. Vs Speed Vs AE Running Time',
      },
      {
        key: '/report-chart/me-fuel-consumption',
        label: 'ME Fuel Consumption',
        hide: currAsset?.isEMS,
      },
      {
        key: '/report-chart/me-fuel-used-vs-running-hour',
        label: 'ME Fuel Used vs Running Hours',
        hide: currAsset?.isEMS || !currAsset?.isSingleEngine,
      },
      {
        key: '/report-chart/port-fuel-used-vs-running-hour',
        label: 'PORT Fuel Used vs Running Hours',
        hide: currAsset?.isEMS || currAsset?.isSingleEngine,
      },
      {
        key: '/report-chart/stb-fuel-used-vs-running-hour',
        label: 'STARBOARD Fuel Used vs Running Hours',
        hide: currAsset?.isEMS || currAsset?.isSingleEngine,
      },
      {
        key: '/report-chart/ae-fuel-consumption',
        label: 'AE Fuel Consumption',
        hide: currAsset?.isSingleEngine,
      },
      {
        key: '/report-data-log',
        label: 'Data Log View',
      },
      {
        key: '/report-operation-mode',
        label: 'Operation Mode',
        hide: !currAsset?.isHaveOperationMode
      },
      {
        key: '/report-event-engine',
        label: 'Event Engine',
        hide: activeCompany?.compId !== '44'
      }
    ];
  }, [currAsset]);

  const FMS_MENUS = useMemo(() => {
    return [
      {
        label: 'Monitoring',
        type: 'group',
        children: [
          {
            key: '/home',
            label: 'Home',
            title: 'Home',
            icon: (
              <Icon
                component={Homesvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/dashboard',
            label: 'Dashboard',
            title: 'Dashboard',
            module: 'master_asset',
            icon: (
              <Icon
                component={Dashboardsvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/report',
            label: ' Report',
            title: 'Report',
            module: 'master_device',
            icon: (
              <Icon
                component={Reportsvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
            children: reportChildren,
          },
        ],
      },
      {
        label: 'Assets & Company',
        type: 'group',
        children: [
          {
            key: '/group',
            label: 'Group',
            title: 'Group',
            module: 'master_group_company',
            icon: (
              <Icon
                component={Groupsvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/company',
            label: 'Company',
            title: 'Company',
            hide: true,
            module: 'master_company',
            icon: (
              <Icon
                component={Companysvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/asset',
            label: ' Asset',
            title: 'Asset',
            module: 'master_asset',
            icon: (
              <Icon
                component={Shipsvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/devices',
            label: 'Device',
            title: 'Device',
            module: 'master_device',
            icon: (
              <Icon
                component={Devicesvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
        ],
      },
      {
        label: 'Options',
        type: 'group',
        children: [
          {
            key: '/master-report',
            label: 'Master Report',
            title: 'Master Report',
            module: [
              'master_report_target_asset',
              'master_report_target_header',
              'master_report_template',
            ],
            icon: (
              <Icon
                component={EmailSvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/operation-mode',
            label: 'Operation Mode',
            title: 'Operation Mode',
            module: [
              'master_rpm_operation_detail',
              'master_rpm_operation_header',
            ],
            icon: (
              <Icon
                component={OperationModeSvg}
                style={{fontSize: 20 }}
              />
            ),
          },
        ],
      },
      {
        label: 'Users Config',
        type: 'group',
        children: [
          {
            key: '/users',
            label: 'Users',
            title: 'Users',
            module: 'master_user',
            icon: (
              <Icon
                component={Usersvg}
                style={{ color: 'transparent', fontSize: 20 }}
              />
            ),
          },
          {
            key: '/roles',
            label: 'Roles',
            title: 'Roles',
            module: 'master_role',
            icon: (
              <Icon
                style={{ color: 'transparent', fontSize: 20 }}
                component={Rolesvg}
              />
            ),
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportChildren, currAsset]);

  const BMS_MENUS = [
    {
      label: 'Monitoring',
      type: 'group',
      children: [
        {
          key: '/home-bms',
          label: 'Home',
          title: 'Home',
          icon: (
            <Icon
              component={Homesvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
        {
          key: '/report-bms-log',
          label: ' Report',
          title: 'Report',
          module: 'master_device',
          icon: (
            <Icon
              component={Reportsvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
      ],
    },
    {
      label: 'Assets & Company',
      type: 'group',
      children: [
        {
          key: '/group',
          label: 'Group',
          title: 'Group',
          module: 'master_group_company',
          icon: (
            <Icon
              component={Groupsvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
        {
          key: '/company',
          label: 'Company',
          title: 'Company',
          module: 'master_company',
          icon: (
            <Icon
              component={Companysvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
        {
          key: '/asset',
          label: ' Asset',
          title: 'Asset',
          module: 'master_asset',
          icon: (
            <Icon
              component={Shipsvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
        {
          key: '/devices',
          label: 'Device',
          title: 'Device',
          module: 'master_device',
          icon: (
            <Icon
              component={Devicesvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
      ],
    },
    {
      label: 'Users Config',
      type: 'group',
      children: [
        {
          key: '/users',
          label: 'Users',
          title: 'Users',
          module: 'master_user',
          icon: (
            <Icon
              component={Usersvg}
              style={{ color: 'transparent', fontSize: 20 }}
            />
          ),
        },
        {
          key: '/roles',
          label: 'Roles',
          title: 'Roles',
          module: 'master_role',
          icon: (
            <Icon
              style={{ color: 'transparent', fontSize: 20 }}
              component={Rolesvg}
            />
          ),
        },
      ],
    },
  ];

  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (access && typeof access === 'object') {
      if (selectedApp && selectedApp.id === 'fms') {
        filterPermission(FMS_MENUS) as MenuProps['items'];
      } else if (selectedApp && selectedApp.id === 'bms') {
        filterPermission(BMS_MENUS) as MenuProps['items'];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access, selectedApp, currAsset]);

  const convertPathName = () => {
    const pathname = location.pathname.split('/');
    const res = '/' + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && 'children' in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : '');
            if (submenu && 'children' in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  'path' in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  function handleClickMenu(key: string) {
    if (
      location.pathname.includes('report-chart') ||
      location.pathname.includes('report-summary') ||
      location.pathname.includes('report-vessel-tracking') ||
      location.pathname.includes('report-data-log') ||
      location.pathname.includes('dashboard')
    ) {
      navigate({
        pathname: key,
        search: location.search,
      });
    } else {
      navigate(key);
    }
  }

  return (
    <MenuCustom
      mode='inline'
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      items={
        !isLoadingAssetSummary &&
        items.map((item: any) => {
          if (item.children && item.children.length > 0) {
            return {
              ...item,
              children: item.children.map((item2: any) => {
                if (item2.children) {
                  return {
                    ...item2,
                    children: item2.children.filter(
                      (item3: any) => !item3.hide
                    ),
                  };
                }
                return item2;
              }),
            };
          }
          return item;
        })
      }
      onClick={({ key }) => {
        handleClickMenu(key);
      }}
    />
  );
};

const MenuCustom = styled(Menu)`
  background: #272a35;
  color: ${({ theme }) => theme.colors.white};
  padding: 12px;
  border-right: 1px solid #272a35;
`;

export default Sidebar;
