import { Button, Tag } from 'antd';
import React from 'react';
import { DeviceProps } from '../../types/device.type';
import {
  HeaderAdditionalInfo,
  ListCompanyItem,
  ListCompanyWrapper,
} from './DataCompanyList';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: any[];
  onClickItem?: (data: DeviceProps) => void;
}

const DataDeviceList: React.FC<Props> = ({ data, onClickItem }) => {
  const navigate = useNavigate();

  function handleGoToDeviceLog(deviceData: DeviceProps) {
    const devcName = `Log Device ${deviceData.devcMass.massName} - ${deviceData.devcName}`;

    navigate(`/devices/${devcName.split(' ').join('-')}`, {
      state: {
        devcName,
        devcMassId: deviceData.devcMassId,
        devcLabel: deviceData.devcLabel,
        devcId: deviceData.devcId,
        devcType: deviceData.devcType,
        devcUniqueId: deviceData.devcUniqueId,
      },
    });
  }

  return (
    <React.Fragment>
      <HeaderAdditionalInfo>
        <div>Device List</div>
        <span>Total {data.length}</span>
      </HeaderAdditionalInfo>

      <ListCompanyWrapper>
        {data.map((item, idx) => (
          <ListDeviceItem
            style={{ cursor: 'default' }}
            key={idx}
            onClick={() => onClickItem && onClickItem(item)}
          >
            <div>
              <ItemLabel>Device Name</ItemLabel>
              <div>{item.devcName}</div>
            </div>
            <div>
              <ItemLabel>Device Unique ID</ItemLabel>
              <div>{item.devcUniqueId}</div>
            </div>
            <Button onClick={() => { handleGoToDeviceLog(item) }} size='small'>
              View Log
            </Button>
          </ListDeviceItem>
        ))}
      </ListCompanyWrapper>
    </React.Fragment>
  );
};

export const ListDeviceItem = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Default};
  align-items: center;
  display: grid;
  gap: 12px;
  cursor: pointer;
  grid-template-columns: 1fr 1fr auto;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: thin solid lightgray;
    overflow: hidden;
    border-radius: 5px;
  }
`;

const ItemLabel = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;
export default DataDeviceList;
