import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Dropdown, Menu } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import { useOktaAuth } from '@okta/okta-react';

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const { logout, loginData } = useAuth();
  const { oktaAuth } = useOktaAuth();

  const handleMenuClick = async (e: any) => {
    if (e.key === 'profile') {
      navigate('/profile');
    } else if (e.key === 'change-password') {
      navigate('/change-password');
    } else {
      logout();
      await oktaAuth.signOut();
    }
  };

  const ContentDropdown = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup title={'Welcome, ' + loginData.user}>
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        {/* <Menu.Item key='profile' icon={<UserOutlined />}>
          My Profil
        </Menu.Item>
        <Menu.Item key='change-password' icon={<LockOutlined />}>
          Change Password
        </Menu.Item> */}
        <Menu.Item key='logout' icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={ContentDropdown}>
      <span>
        <AppAvatar>
          <UserOutlined />
        </AppAvatar>
        <Icon />
      </span>
    </Dropdown>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.grayLighten_Dark};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.grayDarken_Default};
`;

export default UserAvatar;
