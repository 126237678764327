import { useContext, useEffect, useRef, useState } from 'react';
import ConfigContext from '../context/ConfigContext';

export default function useConfigApp() {
  const { config, setConfig } = useContext(ConfigContext);
  const { counterBatch, sidebarCollapsed } = config;

  const toggleSidebar = (value?: boolean) => {
    if (typeof value === 'boolean') {
      setConfig({
        ...config,
        sidebarCollapsed: value,
      });
    } else if (sidebarCollapsed) {
      setConfig({
        ...config,
        sidebarCollapsed: false,
      });
    } else {
      setConfig({
        ...config,
        sidebarCollapsed: true,
      });
    }
  };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (window.innerWidth < 1000) {
      setConfig({
        ...config,
        sidebarCollapsed: true,
      });
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setConfig({
          ...config,
          sidebarCollapsed: true,
        });
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    sidebarCollapsed,
    counterBatch,
    toggleSidebar,
  };
}
