import styled from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useState } from 'react';
import { hChartConfig } from '../../helpers/chart';
import React from 'react';

highchartsMore(Highcharts);

interface IyAxis {
  color: string;
  text: string;
  opposite?: boolean;
  fixed?: boolean;
}

export interface IPropsChartDevice {
  dataXAxis: string[];
  dataYAxis: IyAxis[];
  dataSeries: any;
  title: string;
  customPlotOptions?: any;
}

const HChartDevice = React.forwardRef<any, IPropsChartDevice>(
  ({ dataXAxis, dataYAxis, dataSeries, title, customPlotOptions }, ref) => {
    const [dataChart, setDataChart] = useState<any>();

    const generateYAxis = (data: IyAxis[]) => {
      const yAxis: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> =
        [];
      for (let idx = 0; idx < data.length; idx++) {
        const item = data[idx];
        yAxis.push({
          labels: {
            style: {
              color: item.color,
            },
          },
          title: {
            text: item.text,
            style: {
              color: item.color,
            },
          },
          lineColor: item.color,
          lineWidth: 1,
          tickColor: item.color,
          tickWidth: 1,
          tickLength: 5,
          opposite: item.opposite,
          min: 0,
        });
      }

      return yAxis;
    };

    const createDataSeries = () => {
      let currChartOption = {
        chart: {
          zoomType: 'x',
          scrollablePlotArea: {
            minWidth: 400,
            scrollPositionX: 1,
          },
        },
        title: {
          text: title,
          align: 'left',
        },
        subtitle: {
          text: 'Click and drag in the plot area to zoom in',
          align: 'left',
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          ...hChartConfig.plotOptions,
          ...customPlotOptions,
        },
        xAxis: [
          {
            categories: dataXAxis,
            crosshair: true,
            scrollbar: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return (
              this.points &&
              this.points.reduce(function (s, point) {
                return (
                  s + '<br/>' + point.series.name + ': ' + point.y?.toFixed(2)
                );
              }, '<b>' + this.x + '</b>')
            );
          },
          shared: true,
          crosshairs: true,
        },
        yAxis: generateYAxis(dataYAxis),
        series: dataSeries,
      };

      setDataChart(currChartOption);
    };

    useEffect(() => {
      if (dataSeries) {
        createDataSeries();
      }
    }, [dataSeries]);

    console.log('CHART DEVICE - raw data series: ', dataSeries);
    console.log('CHART DEVICE - data chart:', dataChart);

    return (
      <>
        <div ref={ref} style={{ height: '100%' }}>
          <HighchartsReact
            containerProps={{
              style: {
                height: '100%',
                paddingTop: 20,
                paddingBottom: 20,
              },
            }}
            highcharts={Highcharts}
            options={{
              ...dataChart,
            }}
            allowChartUpdate={true}
            immutable={true}
          />
        </div>
      </>
    );
  }
);

interface IFlexHeader {
  justify: string;
}

const FlexHeader = styled.div<IFlexHeader>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
`;

export default HChartDevice;
