import { AxiosResponse } from 'axios';
import { httpRequest, httpRequestPDF } from '../helpers/api';
import { SummaryHttpResponse } from '../types/summary.type';
import moment from 'moment';
import { OperationModeSeriesType, OperationModeSummaryType } from '../types/asset.type';

const SummarySvc = {
  getSummary(dto: {
    start: string;
    end: string;
    params?: any;
    dataSource?: string;
  }): Promise<AxiosResponse<SummaryHttpResponse>> {
    let dataSource = dto.dataSource || 'gsm';
    if (dataSource === 'satellite') {
      return httpRequest.get(
        `/summary/satellite/aggregated/period/${dto.start}/${dto.end}`,
        { params: dto.params }
      );
    } else {
      return httpRequest.get(
        `/summary/aggregated/period/${dto.start}/${dto.end}`,
        { params: dto.params }
      );
    }
  },

  async getLastData(type: any, device: string[], dataSource?: string) {
    if (type === 'rpm') {
      const responseRpm = await httpRequest.get(`/series/device/${type}/pulse`, {
        params: { device },
      });
      
      return responseRpm.data?.data?.series; 
    }

    if (dataSource === 'satellite') {
      let endTime = moment().startOf('hour').unix();
      let startTime = endTime - 3600 * 24;
      let allData: any[] = [];

      const assRes = await httpRequest.get(`/master/asset`);

      let assets = assRes.data.data;

      for (const element of assets) {
        let params = {
          devcMassId: element.massId,
          devcType: ['gps'],
          aggregatedUnit: 'HOUR',
          aggregatedLength: 1,
          timezone: 7,
        };

        const satelliteData = await httpRequest.get(
          `/summary/satellite/aggregated/period/${startTime}/${endTime}`,
          { params: params }
        );

        let devcData = await httpRequest.get(
          `/master/device?findField=devcMassId&findValue=${element.massId}`
        );
        let gpsDevc = devcData.data.data.filter((el: any) => {
          return el.devcType === 'gps';
        });
        let arrayOfSatData = Object.values(satelliteData.data.data.gps.data);

        allData.push({
          data: arrayOfSatData[arrayOfSatData.length - 1],
          summary: satelliteData.data.data.gps.summary,
          gpsDevc: gpsDevc[0],
        });
      }

      return allData
        .filter((elm) => elm.data)
        .map((elm) => {
          return {
            altitude: null,
            datetime: elm.data?.datetime,
            deviceId: elm.gpsDevc?.devcUniqueId,
            distance: elm.summary?.distance,
            geoidal: null,
            id: elm.data?.id,
            latitude: elm.data?.latitude,
            longitude: elm.data?.longitude,
            polyline: null,
            satellites: null,
            speed: elm.summary?.calculatedSpeed,
            timestamp: elm.data?.timestamp,
            track: 0,
          };
        });
    } 
    
    const res = await httpRequest.get(`/series/device/${type}/pulse`, {
      params: { device, type },
    });

    return res.data.data.series;
  },

  downloadReportPDF(
    data: {
      start: number;
      end: number;
    },
    params: any
  ): Promise<any> {
    return httpRequestPDF.get(
      `/reports/report/print/period/${data.start}/${data.end}`,
      {
        params: {
          ...params,
        },
      }
    );
  },

  async getSummaryOperationMode(dto: {
    start: string | number;
    end: string | number;
    params?: any;
    dataSource?: string;
  }): Promise<AxiosResponse<any>> {
    return httpRequest.get(`/summary/operation_mode/${dto.start}/${dto.end}`, {
      params: dto.params,
    });
  },

  async getSeriesOperationMode(dto: {
    start: string | number;
    end: string | number;
    params?: any;
    dataSource?: string;
  }): Promise<AxiosResponse<any>> {
    return httpRequest.get(`/summary/operation_mode/series/${dto.start}/${dto.end}`, {
      params: dto.params,
    });
  },
};

export default SummarySvc;
