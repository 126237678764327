import { Select } from 'antd';
import useAsset from '../../hooks/useAsset';

const ChartTypeSwitch = () => {
  const { setChartType, chartType } = useAsset();
  function handleChangeChartType(e: string) {
    setChartType(e);
  }

  return (
    <Select
      size='small'
      defaultValue={chartType}
      style={{
        width: 120,
        marginRight: 10,
      }}
      onChange={handleChangeChartType}
      options={[
        {
          value: 'column',
          label: 'Default',
        },
        {
          value: 'spline',
          label: 'Line',
        },
      ]}
    />
  );
};

export default ChartTypeSwitch;
