import styled from 'styled-components';
import { Button, Input, Modal, Pagination, Select, Table, message } from 'antd';
import CustomForm from '../../../components/CustomForm';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import useFetchList from '../../../hooks/useFetchList';
import { INITIAL_QUERY } from '../../../helpers/pagination';
import { IReportTemplate } from '../../../types/masterReport.type';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { GroupActionButton } from '../../groupCompany';
import { MasterReportTemplateSvc } from '../../../services/MasterReportSvc';
import useCompany from '../../../hooks/useCompany';
import ModalMasterReportTemplate from './modalMasterReportTemplate';

interface IQueryMasterReportTemplate {
  findField?: string;
  findValue?: string;
  mrtpCompId?: string;
}

const MasterReportTemplate: React.FC<{
  handleSetSearchParams: (value: string) => void;
  activeCompId: string;
}> = (props) => {
  const [isOpenModalEditTemplate, setIsOpenModalEditTemplate] =
    useState<boolean>(false);
  const [reportTemplateDetail, setReportTemplateDetail] =
    useState<IReportTemplate>();
  const [selectedCompId, setSelectedCompId] = useState<string>();
  const { availableCompanies, setAvailableCompaniesRaw } = useCompany();

  const { handlePermission, loginData } = useAuth();

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
    setQuery,
  } = useFetchList<IReportTemplate, IQueryMasterReportTemplate>({
    // fetchable: false,
    endpoint: '/master/report_template',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
    },
  });

  function setNewQuery(e: string, field: string) {
    if (e !== 'all') {
      setQuery({
        ...query,
        page: 1,
        findField: field,
        findValue: e,
      });
    } else {
      setQuery({
        page: 1,
        max: INITIAL_QUERY.max,
      });
    }
  }

  async function handleAfterSubmit() {
    setIsOpenModalEditTemplate(false);
    setReportTemplateDetail(undefined);
    await fetchList();
  }

  const handleDeleteReportTemplate = async (
    mrtpId: string,
    mrtpName: string
  ) => {
    try {
      await MasterReportTemplateSvc.deleteMasterReport(mrtpId);
      message.success('Success delete data ' + mrtpName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const confirmDelete = (mrtpId: string, mrtpName: string) => {
    Modal.confirm({
      title: 'Delete Report Template',
      content: `Are you sure want to delete "${mrtpName}"?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteReportTemplate(mrtpId, mrtpName);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  const handleMenuClick = (item: any, data: IReportTemplate) => {
    setReportTemplateDetail(data);
    if (item === 'edit') {
      setIsOpenModalEditTemplate(true);
    } else if (item === 'delete') {
      confirmDelete(data.mrtpId, data.mrtpName);
    }
  };

  function onChangeCompany(compId: string) {
    setSelectedCompId(compId);
    setNewQuery(compId, 'mrtpCompId');
    props.handleSetSearchParams(compId);
  }

  useEffect(() => {
    if (props.activeCompId) {
      setSelectedCompId(props.activeCompId);
      onChangeCompany(props.activeCompId);
    }
  }, [props.activeCompId]);

  useEffect(() => {
    if (loginData && loginData.company && loginData.company.available) {
      setAvailableCompaniesRaw(loginData.company.available);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  const availableCompaniesMapped = useMemo(() => {
    if (availableCompanies && availableCompanies.length > 0) {
      const newCompanies = availableCompanies?.map((item) => ({
        value: item.compId,
        label: item.compName,
      }));

      return [{ value: 'all', label: 'All Company' }, ...newCompanies];
    }

    return [];
  }, [availableCompanies]);

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.value && selectedCompId) {
      setNewQuery(selectedCompId, 'mrtpCompId');
    } else {
      setSearch({
        findField: 'mrtpName',
        findValue: e.target.value,
      });
    }
  }

  const columns: ColumnsType<IReportTemplate> = [
    {
      title: 'Template Name',
      dataIndex: 'mrtpName',
    },
    {
      title: 'Company',
      dataIndex: 'mrtpCompId',
      render: (value) => {
        const companyFound = availableCompanies?.find(
          (item) => item.compId === value
        );
        return <span>{companyFound ? companyFound.compName : 'Not set'}</span>;
      },
    },
    {
      title: 'Created by',
      dataIndex: 'mrtpCreatedUserId',
    },
    {
      title: 'Created at',
      dataIndex: 'mrtpCreatedTime',
      render: (date: Date) => <span>{moment(date).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Updated at',
      dataIndex: 'mrtpUpdatedTime',
      render: (date: Date) => <span>{moment(date).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: IReportTemplate) => {
        return (
          <GroupActionButton>
            {handlePermission(['master_report_template'], 'update') && (
              <Button
                onClick={() => handleMenuClick('edit', record)}
                size='small'
                value='edit'
              >
                Edit
              </Button>
            )}
            {handlePermission(['master_report_template'], 'delete') && (
              <Button
                onClick={() => handleMenuClick('delete', record)}
                size='small'
                danger
                value='delete'
              >
                Delete
              </Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  return (
    <ContainerContentMasterReport>
      <FilterContainer>
        <CustomForm label='Search'>
          <Input
            placeholder='Search by template name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={handleSearch}
          />
        </CustomForm>
        {handlePermission(['master_company']) && (
          <CustomForm label='Company'>
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label.toLowerCase() ?? '').toLowerCase()
                  )
              }
              value={selectedCompId}
              onChange={onChangeCompany}
              options={availableCompaniesMapped || []}
            />
          </CustomForm>
        )}
        <div />
        {handlePermission(['master_report_template'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsOpenModalEditTemplate(true);
            }}
          >
            Add Template
          </Button>
        )}
      </FilterContainer>

      <Table
        loading={isLoading}
        size='small'
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => changePage(page, perPage)}
      />

      {selectedCompId && (
        <ModalMasterReportTemplate
          isOpen={isOpenModalEditTemplate}
          data={reportTemplateDetail}
          afterSubmit={handleAfterSubmit}
          compId={selectedCompId}
        />
      )}
    </ContainerContentMasterReport>
  );
};

const ContainerContentMasterReport = styled.div`
  padding: 0 20px;
  background-color: white;
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 250px 350px 1fr auto;
  padding: 0px 0px 15px;
`;

export default MasterReportTemplate;
