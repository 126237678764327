import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import {
  Button,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  message,
  Tag,
} from 'antd';
import CustomForm from '../../components/CustomForm';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { INITIAL_QUERY } from '../../helpers/pagination';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { GroupActionButton } from '../groupCompany';
import {
  IOperationDetail,
  IOperationHeader,
} from '../../types/operationMode.type';
import ModalOperationModeDetail from './components/modalOperationModeDetail';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';
import MasterOperatrionModeSvc from '../../services/OperationModeSvc';

interface ILocation {
  mrohId: string;
}

const OperationModeDetail: React.FC = () => {
  const navigate = useNavigate();
  const { mrohId } = useParams<keyof ILocation>() as ILocation;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedCompId, setSelectedCompId] = useState<string>();
  const { handlePermission, loginData } = useAuth();
  const [detail, setDetail] = useState<IOperationDetail>();

  const columns: ColumnsType<IOperationDetail> = [
    {
      title: 'Name',
      dataIndex: 'mrodName',
    },
    {
      title: 'RPM Max',
      dataIndex: 'mrodRpmMax',
    },
    {
      title: 'Cons Rate',
      dataIndex: 'mrodConsRate',
      render: (text) => {
        return parseFloat(text) + ' L/m';
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <GroupActionButton>
            {handlePermission(['master_rpm_operation_detail'], 'update') && (
              <Button
                onClick={() => handleMenuClick('edit', record)}
                size='small'
                value='edit'
              >
                Edit
              </Button>
            )}
            {handlePermission(['master_rpm_operation_detail'], 'delete') && (
              <Button
                onClick={() => handleMenuClick('delete', record)}
                size='small'
                danger
                value='delete'
              >
                Delete
              </Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
    setQuery,
  } = useFetchList<IOperationDetail>({
    endpoint: '/master/Rpm_Operation_Detail',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
      findField: 'mrodMrohId',
      findValue: mrohId,
    },
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!value) {
      setQuery(() => ({
        page: 1,
        max: INITIAL_QUERY.max,
        findField: 'mrodMrohId',
        findValue: mrohId,
      }));
    } else {
      setSearch({
        findField: 'mrodName',
        findValue: e.currentTarget.value,
      });
    }
  };

  const handleMenuClick = (item: any, data: IOperationDetail) => {
    if (item === 'edit') {
      setDetail({
        ...data,
        mrodConsRate: String(parseFloat(data.mrodConsRate)),
      });
      setIsOpenModal(true);
    } else if (item === 'delete') {
      confirmDelete(data.mrodId, data.mrodName);
    }
  };

  async function handleDeleteMasterDetail(id: string) {
    try {
      await MasterOperatrionModeSvc.deleteMasterDetail(id);
    } catch (error) {
      console.error('failed delete master detail', error);
    }
  }

  const confirmDelete = (id: string, name: string) => {
    Modal.confirm({
      title: 'Delete Operation Mode Detail',
      content: `Are you sure want to delete this "${name}" operation mode detail?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteMasterDetail(id);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  const handleAfterSubmit = () => {
    setIsOpenModal(false);
    setQuery((currQuery) => ({
      ...currQuery,
      page: 1,
    }));
  };

  return (
    <Container>
      <FilterContainer style={{ marginTop: 10 }}>
        <CustomForm label='Search'>
          <Input
            placeholder='Search by name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => handleSearch(e)}
          />
        </CustomForm>
        <div />
        <div />

        {handlePermission(['master_rpm_operation_detail'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Operation Mode Detail
          </Button>
        )}
          
      </FilterContainer>

      <Table
        loading={isLoading}
        size='small'
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => {
          changePage(page, perPage);
        }}
      />

      <ModalOperationModeDetail
        isOpen={isOpenModal}
        data={detail}
        mrodMrohId={mrohId}
        afterSubmit={handleAfterSubmit}
        compId={selectedCompId ?? ''}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 20px;
  background-color: white;
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 250px 350px 1fr auto;
  padding: 0px 0px 15px;
`;

export default OperationModeDetail;
