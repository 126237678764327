import { Button, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useAsset from '../../hooks/useAsset';
import {
  generateTimestampArray,
  roundTimestampsToNearestMinute,
} from '../../helpers/dateUtil';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import { useLocation, useSearchParams } from 'react-router-dom';
import useFilter from '../../hooks/useFilter';
import { ContainerLoading, LoadingWrapper } from '.';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import { intervalOptions } from '../../helpers/map';
import * as XLSX from 'xlsx';
import DataCompletionInfo from '../../components/DataCompletionInfo';
import CardInfo from '../../screens/dashboard/components/CardInfo';

const windowHeight = window.innerHeight;

const ReportBunkerLog: React.FC = () => {
  const {
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAssetSummary,
    dataCompletion,
  } = useAsset();
  const { dataFilter } = useFilter();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  const { activeCompany, fetchCompanyData } = useCompany();
  const currMassId = currAsset ? currAsset.massId : '';
  const [searchParams, setSearchParams] = useSearchParams();

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (dataFetchedCompRef.current) return;
    dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : dataFilter.massId
        ? dataFilter.massId
        : 'initial',
      aggregatedLength: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataTables, setDataTables] = useState<any>([]);
  const [bunkerData, setBunkerData] = useState<any>([]);
  const { isLoadingFilter } = useFilter();

  const columns: any = [
    {
      dataIndex: 'timestamp',
      title: 'Date Time',
      class: 'text-center',
      render: (value: number) => {
        return value;
      },
    },
    {
      dataIndex: 'volumeFlowrate',
      title: 'Volume Flow Rate',
      class: 'text-center',
      render: (text: any) => {
        return typeof text === 'number' ? text.toFixed(2) : text;
      },
    },
    {
      dataIndex: 'volumeTotal',
      title: 'Volume Total',
      class: 'text-center',
      render: (text: any) => {
        return typeof text === 'number' ? text.toFixed(2) : text;
      },
    },
    { dataIndex: 'density', title: 'Density', class: 'text-center' },
    {
      dataIndex: 'massFlowrate',
      title: 'Mass Flow Rate',
      class: 'text-center',
      render: (text: any) => {
        return typeof text === 'number' ? text.toFixed(2) : text;
      },
    },
    {
      dataIndex: 'massTotal',
      title: 'Mass Total',
      class: 'text-center',
      render: (text: any) => {
        return typeof text === 'number' ? text.toFixed(2) : text;
      },
    },
    {
      dataIndex: 'temperature',
      title: 'Temperature',
      class: 'text-center',
      render: (text: any) => {
        return typeof text === 'number' ? text.toFixed(2) : text;
      },
    },
  ];

  useEffect(() => {
    if (currAsset && currMassId && currAsset.dataSummary) {
      const generateDataLog = () => {
        let newCategories: any[] = generateTimestampArray(
          moment(dataFilter.range.startAt).unix(),
          moment(dataFilter.range.endAt).unix(),
          dataFilter.interval === 'MINUTE'
            ? 60
            : dataFilter.interval === 'HOUR'
            ? 3600
            : 86400
        );

        const dataTables = newCategories.map((timestamp) => {
          const flowmeter = currAsset.dataSummary?.flowmeter?.data.find(
            (item) => {
              if (
                item.BUNKER_IN &&
                roundTimestampsToNearestMinute(
                  item.BUNKER_IN.timestamp * 1000
                ) /
                  1000 ===
                  timestamp
              ) {
                return item;
              } else if (
                item.PORT_IN &&
                roundTimestampsToNearestMinute(item.PORT_IN.timestamp * 1000) /
                  1000 ===
                  timestamp
              ) {
                return item;
              } else {
                return undefined;
              }
            }
          );

          if (flowmeter) {
            return {
              id: timestamp,
              timestamp: moment(timestamp * 1000).format("DD MMM YYYY, HH:mm:ss"),
              volumeFlowrate: flowmeter?.BUNKER_IN?.volumeFlowrate,
              volumeTotal: flowmeter?.BUNKER_IN?.volumeTotal,
              density: flowmeter?.BUNKER_IN?.density,
              massFlowrate: flowmeter?.BUNKER_IN?.massFlowrate,
              massTotal: flowmeter?.BUNKER_IN?.massTotal,
              temperature: flowmeter?.BUNKER_IN?.temperature,
            };
          } else {
            return {
              id: timestamp,
              timestamp: moment(timestamp * 1000).format("DD MMM YYYY, HH:mm:ss"),
              volumeFlowrate: '-',
              volumeTotal: '-',
              density: '-',
              massFlowrate: '-',
              massTotal: '-',
              temperature: '-',
            };
          }
        });

        setDataTables(dataTables);
      };

      setBunkerData(currAsset?.dataSummary?.flowmeter?.summary?.BUNKER_IN);
      generateDataLog();
    }

    // eslint-disable-next-spline react-hooks/exhaustive-deps
  }, [currAsset, currMassId]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const downloadExcel = () => {
    const start = moment(dataFilter.range.startAt).format(
      'DD_MM_YYYY_HH_mm_ss'
    );
    const end = moment(dataFilter.range.endAt).format('DD_MM_YYYY_HH_mm_ss');
    const range_excel = start + '-' + end;

    const newRefinedData = dataTables.map((item: any) => {
      const newItem = {} as any;
      Object.keys(item)
        .filter((key) => !["id"].includes(key))
        .forEach((key) => {
          if (key === "coordinate") {
            newItem[key] = item[key] ? item[key].split(" ").join(", ") : null;
          } else if (
            key === "timestamp" ||
            key === "dateTime" ||
            typeof item[key] === "string"
          ) {
            newItem[key] = item[key];
          } else if (typeof item[key] === "number") {
            newItem[key] = parseFloat(item[key].toFixed(2));
          } else {
            newItem[key] = 0;
          }
        });
      return newItem;
    });
    const data = XLSX.utils.json_to_sheet(newRefinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(
      wb,
      `${currAsset?.massName.split(' ').join('_')}-${range_excel}.xlsx`
    );
  };

  const location = useLocation();

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <ReportActionContent>
        <FilterDashboard
          isLog
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
        <Button
          loading={isLoadingAssetSummary && dataTables.length === 0}
          disabled={isLoadingAssetSummary && dataTables.length === 0}
          onClick={downloadExcel}
          style={{ marginBottom: 8, background: '#54C95A' }}
        >
          <span style={{ color: 'white' }}>Export XLSX</span>
        </Button>
      </ReportActionContent>

      {isLoadingAssetSummary || isLoadingFilter ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : dataTables && dataTables.length > 0 ? (
        <Content>
          <SummaryContainer>
            <CardInfo
              showChart
              style={{ width: '100%' }}
              label='MASS TOTAL'
              value={bunkerData?._totalMassFlow?.toFixed(2) ?? '0.00'}
              unit='MT'
            />
            <CardInfo
              showChart
              style={{ width: '100%' }}
              label='MASS FLOW RATE'
              value={bunkerData?._averageMassFlowrate?.toFixed(2) ?? '0.00'}
              unit='MT/HR'
            />
            <CardInfo
              showChart
              style={{ width: '100%' }}
              label='DENSITY'
              value={bunkerData?._averageDens?.toFixed(2) ?? '0.00'}
              unit='g/cm³'
            />
            <CardInfo
              showChart
              style={{ width: '100%' }}
              label='TEMPERATURE'
              value={bunkerData?._averageTemp?.toFixed(2) ?? '0.00'}
              unit='°C'
            />
          </SummaryContainer>
          <Table
            size='small'
            bordered
            columns={columns}
            pagination={{
              pageSize: 500,
            }}
            scroll={{ y: windowHeight - 300, x: 700 }}
            dataSource={dataTables}
          />
        </Content>
      ) : (
        <ContainerLoading>
          <LoadingWrapper>
            <InfoCircleOutlined />
            <div>Data not found</div>
          </LoadingWrapper>
        </ContainerLoading>
      )}
    </React.Fragment>
  );
};

export default ReportBunkerLog;

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: flex-end;
  padding-right: 20px;
`;

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  width: 100%;
  padding: 20px;
`;

const HandleAccordion = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
`;

const Content = styled.div`
  padding: 20px 20px;
  height: auto;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

interface ContentWrapperProps {
  odd: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Dark};
  padding: 10px 0;
  display: grid;
  gap: 20px 15px;
  position: relative;
  cursor: pointer;
  grid-template-columns: ${(props) => (props.odd ? '1fr' : 'repeat(4, 1fr)')};
`;

const Wrapper = styled.div``;

const ContentItem = styled.div`
  h3 {
    font-weight: 600;
    margin: 0;
  }
`;

interface FlexProps {
  show: boolean;
}

const FlexColumn = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 15px;
`;

const FlexRow = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'grid' : 'none')};
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`;
