import OktaAuth from '@okta/okta-auth-js';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { useEffect, useMemo, useRef, useState } from 'react';
import AuthSvc from '../../../services/AuthSvc';
import { generateHourArray, generateTimestampArray } from '../../../helpers/dateUtil';
import useAsset from '../../../hooks/useAsset';
import styled from 'styled-components';
import { Spin } from 'antd';
import '../../../assets/daily-report.css';
import ReportDailyPage from './ReportDailyPage';
import ReportSummary from '../components/ReportSummary';
import ReportBodyVessel from '../components/ReportBodyVessel';
import HChartRpmFuelSpeed from '../../../components/charts/HChartRpmFuelSpeed';
import HChartFuelRunningHour from '../../../components/charts/HChartFuelRunningHour';
import HChartMeFuelCons from '../../../components/charts/HChartMeFuelCons';
import HChartAEFuelCons from '../../../components/charts/HChartAEFuelCons';
import { useLocation } from 'react-router';
import { intervalOptions } from '../../../helpers/map';
import useFilter from '../../../hooks/useFilter';
import { EventEngine } from '../components/event/EventEngine';
import SeriesSvc from '../../../services/SeriesSvc';
import { DeviceType } from '../../../types/device.type';
import DeviceSvc from '../../../services/DeviceSvc';

moment.tz.setDefault("Asia/Jakarta");

interface IDataQuery {
  user: string | null;
  pass: string | null;
  grcpId: string | null;
  compId: string | null;
  devcMassId: string | null;
  start: number | null;
  end: number | null;
  isProd: boolean | null;
}

const ReportDaily = () => {
  const authClient = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    // scope: "openid profile email",
    // Use authorization_code flow
    responseType: 'code',
    pkce: false,
  });
  const [dataQuery, setDataQuery] = useState<IDataQuery>({
    user: null,
    pass: null,
    grcpId: null,
    compId: null,
    devcMassId: null,
    start: null,
    end: null,
    isProd: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState<any>(new Date());
  const currDevcMassId = dataQuery.devcMassId || null;
  const { pathname } = useLocation();
  const { assets2, fetchAssetsWithLastData, isLoadingAssetSummary } =
    useAsset();
  const dataFetchedRef = useRef(false);
  const refVesselTracking: any = useRef();
  const refChart: any = useRef();
  const { setDataFilter } = useFilter();

  const [portEventData, setPortEventData] = useState<any[]>([]);
  const [centerEventData, setCenterEventData] = useState<any[]>([]);
  const [starboardEventData, setStarboardEventData] = useState<any[]>([]);
  const [summaryEventData, setSummaryEventData] = useState<{
    portOn: number;
    portOff: number;
    centerOn: number;
    centerOff: number;
    starboardOn: number;
    starboardOff: number;
  }>({
    portOn: 0,
    portOff: 0,
    centerOn: 0,
    centerOff: 0,
    starboardOn: 0,
    starboardOff: 0,
  });

  const init = async () => {
    setIsLoading(true);
    try {
      const searchParams = new URLSearchParams(document.location.search);
      const user = searchParams.get('u');
      const pass = searchParams.get('p');
      const grcpId = searchParams.get('grcpId');
      const compId = searchParams.get('compId');
      const devcMassId = searchParams.get('devcMassId');
      const start = searchParams.get('start');
      const end = searchParams.get('end');
      const isProd = searchParams.get('isProd');

      if (start && end && user && pass && devcMassId && compId) {
        setDataQuery({
          user,
          pass,
          grcpId,
          compId,
          devcMassId,
          start: Number(start),
          end: Number(end),
          isProd: isProd === 'true' ? true : false,
        });

        setCurrentTime(
          moment()
            // .zone(moment().utcOffset())
            .format('ddd MMM DD YYYY HH:mm:ss')
        );

        setDataFilter((current) => ({ ...current, interval: 'HOUR' }));

        await oktaLogin({
          username: user,
          password: pass,
        });

        await AuthSvc.setCompany(compId);

        await fetchAssetsWithLastData({
          aggregatedUnit: intervalOptions[1].value,
          start,
          end,
          devcMassId,
        });
        
        const devices = await DeviceSvc.getDevicesByAssetId({findField: 'devcMassId', findValue: devcMassId});

        const gatewayDevice = devices?.data?.data?.find((elm: DeviceType) => elm?.devcType === 'gateway');
        const rpmDevice = devices?.data?.data?.find((elm: DeviceType) => elm?.devcType === 'rpm');

        const gatewayResponse = await SeriesSvc.getAggregatedDataByPeriod('gateway', Number(start) / 1000, Number(end) / 1000, {
          params: {
            aggregatedUnit: 'MINUTE',
            device: [gatewayDevice?.devcUniqueId],
          },
          headers: {
            'Cache-Control': 'max-age=3600'
          }
        })
        const rpmResponse = await SeriesSvc.getAggregatedDataByPeriod('rpm', Number(start) / 1000, Number(end) / 1000, {
          params: {
            aggregatedUnit: 'MINUTE',
            device: [rpmDevice?.devcUniqueId],
          },
          headers: {
            'Cache-Control': 'max-age=3600'
          }
        })

        const gatewayData = gatewayResponse?.data?.data?.series;
        const rpmData = rpmResponse?.data?.data?.series;

        const portData = rpmData.filter((elm: any) => elm?.type === 'PORT');
        const ctrData = rpmData.filter((elm: any) => elm?.type === 'CENTER');
        const stbData = rpmData.filter((elm: any) => elm?.type === 'STARBOARD');

        const timestamps = generateTimestampArray(Number(start) / 1000, Number(end) / 1000, 60);

        const timePortData = timestamps.map((timestamp) => {
          const currPortData = portData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);
          const currGatewayData = gatewayData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);

          if (!currPortData && !currGatewayData) {
            return undefined;
          }

          if (currPortData?.rpm >= 300 && currGatewayData) {
            return {
              timestamp,
              engine: "port",
              status: "on",
              rpm: currPortData?.rpm
            }
          }

          if (currGatewayData && (!currPortData || currPortData?.rpm < 300)) {
            return {
              timestamp,
              engine: "port",
              status: 'off',
            }
          }

          return undefined;
        }).filter(elm => elm !== undefined);

        const timeCtrData = timestamps.map((timestamp) => {
          const currCtrData = ctrData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);
          const currGatewayData = gatewayData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);

          if (currCtrData?.rpm >= 300 && currGatewayData) {
            return {
              timestamp,
              engine: "center",
              status: currCtrData && currGatewayData ? "on" : 'off',
              rpm: currCtrData?.rpm
            }
          }

          if (currGatewayData && (!currCtrData || currCtrData?.rpm < 300)) {
            return {
              timestamp,
              engine: "center",
              status: 'off',
            }
          }

          return undefined;
        }).filter(elm => elm !== undefined);

        const timeStbData = timestamps.map((timestamp) => {
          const currStbData = stbData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);
          const currGatewayData = gatewayData.find((elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp);

          if (currStbData?.rpm >= 300 && currGatewayData) {
            return {
              timestamp,
              engine: "starboard",
              status: "on",
              rpm: currStbData?.rpm
            }
          }

          if (currGatewayData && (!currStbData || currStbData?.rpm < 300)) {
            return {
              timestamp,
              engine: "starboard",
              status: 'off',
            }
          }

          return undefined;
        }).filter((elm) => elm !== undefined);

        /**
         * Generate Event Summary
         */

        const eventSummary = {
          portOn: timePortData.filter(elm => elm?.status === 'on').length,
          portOff: timePortData.filter(elm => elm?.status === 'off').length,
          centerOn: timeCtrData.filter(elm => elm?.status === 'on').length,
          centerOff: timeCtrData.filter(elm => elm?.status === 'off').length,
          starboardOn: timeStbData.filter(elm => elm?.status === 'on').length,
          starboardOff: timeStbData.filter(elm => elm?.status === 'off').length,
        };

        setSummaryEventData(eventSummary);

        /**
         * Generate Event Log
         */

        const eventPortData: any[] = [];

        for (let i = 0; i < timePortData.length; i++) {
          if (i === 0) {
            eventPortData.push({
              start: timePortData[i]?.timestamp,
              end: timePortData[i]?.timestamp,
              engine: timePortData[i]?.engine,
              status: timePortData[i]?.status
            });
            continue;
          }

          let dataBefore = timePortData[i - 1];
          let dataCurrent = timePortData[i];
          if (dataCurrent?.status === dataBefore?.status) {
            eventPortData[eventPortData.length - 1].end = dataCurrent?.timestamp;
          } else {
            eventPortData.push({
              start: dataCurrent?.timestamp,
              end: dataCurrent?.timestamp,
              engine: dataCurrent?.engine,
              status: dataCurrent?.status
            });
          }
        }

        const eventCtrData: any[] = [];

        for (let i = 0; i < timeCtrData.length; i++) {
          if (i === 0) {
            eventCtrData.push({
              start: timeCtrData[i]?.timestamp,
              end: timeCtrData[i]?.timestamp,
              engine: timeCtrData[i]?.engine,
              status: timeCtrData[i]?.status
            });
            continue;
          }

          let dataBefore = timeCtrData[i - 1];
          let dataCurrent = timeCtrData[i];
          if (dataCurrent?.status === dataBefore?.status) {
            eventCtrData[eventCtrData.length - 1].end = dataCurrent?.timestamp;
          } else {
            eventCtrData.push({
              start: dataCurrent?.timestamp,
              end: dataCurrent?.timestamp,
              engine: dataCurrent?.engine,
              status: dataCurrent?.status
            });
          }
        }

        const eventStbData: any[] = [];

        for (let i = 0; i < timeStbData.length; i++) {
          if (i === 0) {
            eventStbData.push({
              start: timeStbData[i]?.timestamp,
              end: timeStbData[i]?.timestamp,
              engine: timeStbData[i]?.engine,
              status: timeStbData[i]?.status
            });
            continue;
          }

          let dataBefore = timeStbData[i - 1];
          let dataCurrent = timeStbData[i];
          if (dataCurrent?.status === dataBefore?.status) {
            eventStbData[eventStbData.length - 1].end = dataCurrent?.timestamp;
          } else {
            eventStbData.push({
              start: dataCurrent?.timestamp,
              end: dataCurrent?.timestamp,
              engine: dataCurrent?.engine,
              status: dataCurrent?.status
            });
          }
        }

        setPortEventData(eventPortData);
        setCenterEventData(eventCtrData);
        setStarboardEventData(eventStbData);
      }
    } catch (error) {
      console.error('failed init data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const oktaLogin = async (data: { username: string; password: string }) => {
    try {
      const resAuth = await axios.post(
        `${process.env.REACT_APP_API_OKTA}/api/v1/authn`,
        {
          username: data.username,
          password: data.password,
        }
      );
      if (resAuth.status === 200) {
        const resAuthClient = await authClient.token.getWithoutPrompt({
          clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
          responseType: ['token', 'id_token'],
          sessionToken: resAuth.data.sessionToken,
          redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
        });

        await AuthSvc.signInWithToken(
          resAuthClient.tokens.accessToken?.accessToken
        );
      }
    } catch (error: any) {
      console.error('failed okta login', error);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    init();
  }, []);

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === currDevcMassId);
  }, [assets2, currDevcMassId]);

  const currMassId = currAsset ? currAsset.massId : '';
  const currMassName = currAsset ? currAsset.massName : '';
  const isSingleEngine = currAsset?.isSingleEngine || false;
  const isEMS = currAsset?.isEMS || false;

  const isLoadingData = useMemo(() => {
    return isLoading || isLoadingAssetSummary;
  }, [isLoading, isLoadingAssetSummary]);

  const currentPath = useMemo(() => {
  if (!pathname.includes('report-vessel-tracking') && currAsset && currAsset.dataSummary && currAsset.dataSummary.gps && currAsset.dataSummary.gps.data) {
    return currAsset.dataSummary.gps.data.map((item) => [
      item.latitude,
      item.longitude,
    ]);
  } else {
    return [];
  }
}, [pathname, currAsset]);

  const columns = useMemo(() => {
    return generateHourArray(dataQuery.start, dataQuery.end);
  }, [dataQuery.start]);

  const pageProps = {
    start: dataQuery.start,
    end: dataQuery.end,
    massName: currMassName,
    currentTime: currentTime,
    lastPage: isEMS ? 4 : isSingleEngine ? 6 : 7,
  };

  if (isLoadingData) {
    return (
      <ContainerLoading>
        <LoadingWrapper>
          <Spin />
          <div>Loading data...</div>
        </LoadingWrapper>
      </ContainerLoading>
    );
  }

  return (
    <>
      {currAsset && currAsset.dataSummary ? (
        <>
          <ReportDailyPage {...pageProps} title='Summary' page={1}>
            <ReportSummary
              isSingleEngine={isSingleEngine}
              massId={currMassId}
              isEMS={isEMS}
              data={currAsset?.dataSummary}
              isDailyReport
              compId={currAsset?.massCompId}
              isHaveOperationMode={currAsset?.isHaveOperationMode}
              isThreeEngine={currAsset?.isThreeEngine}
              summaryEventData={summaryEventData}
            />
          </ReportDailyPage>

          <ReportDailyPage {...pageProps} title='Vessel Tracking' page={2}>
            {currAsset.lastDataGps && currAsset.firstDataGps && (
              <ReportBodyVessel
                ref={refVesselTracking}
                massId={currMassId}
                lastDataGPS={currAsset.lastDataGps}
                firstDataGPS={currAsset.firstDataGps}
                currentPath={currentPath}
                isDailyReport
                avgSpeed={
                  currAsset?.dataSummary?.gps?.summary?.averageSpeed || 0
                }
                totalCruise={
                  currAsset?.dataSummary?.gps?.summary?.totalDistance || 0
                }
              />
            )}
          </ReportDailyPage>

          <ReportDailyPage
            {...pageProps}
            title={
              currDevcMassId === '42'
                ? 'RPM vs Fuel Consumption vs Speed vs ME Running Time'
                : isEMS
                  ? 'RPM vs Speed vs AE Running Time'
                  : 'RPM vs Speed vs Fuel Consumption vs AE Running Time'
            }
            page={3}
          >
            <HChartRpmFuelSpeed
              ref={refChart}
              compId={currAsset.massCompId}
              massId={currAsset.massId}
              isEMS={currAsset.isEMS}
              isSingleEngine={currAsset.isSingleEngine}
              data={currAsset.dataSummary || undefined}
              columns={columns}
              isDailyReport
            />
          </ReportDailyPage>

          {!isEMS && (
            <>
              {isSingleEngine ? (
                <ReportDailyPage
                  {...pageProps}
                  title={'ME Fuel Consumption vs Running Hour'}
                  page={4}
                >
                  <HChartFuelRunningHour
                    type='MAINENGINE'
                    ref={refChart}
                    columns={columns}
                    isSingleEngine={currAsset.isSingleEngine}
                    data={currAsset.dataSummary || undefined}
                    isDailyReport
                  />
                </ReportDailyPage>
              ) : (
                <>
                  <ReportDailyPage
                    {...pageProps}
                    title={'Port Fuel Consumption vs Running Hour'}
                    page={4}
                  >
                    <HChartFuelRunningHour
                      type='PORT'
                      ref={refChart}
                      columns={columns}
                      isSingleEngine={currAsset.isSingleEngine}
                      data={currAsset.dataSummary || undefined}
                      isDailyReport
                    />
                  </ReportDailyPage>
                  <ReportDailyPage
                    {...pageProps}
                    title={'Starboard Fuel Consumption vs Running Hour'}
                    page={5}
                  >
                    <HChartFuelRunningHour
                      type='STARBOARD'
                      ref={refChart}
                      columns={columns}
                      isSingleEngine={currAsset.isSingleEngine}
                      data={currAsset.dataSummary || undefined}
                      isDailyReport
                    />
                  </ReportDailyPage>
                </>
              )}

              <ReportDailyPage
                {...pageProps}
                title={'ME Fuel Consumption'}
                page={isSingleEngine ? 5 : 6}
              >
                <HChartMeFuelCons
                  ref={refChart}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                  isDailyReport
                />
              </ReportDailyPage>
            </>
          )}

          <ReportDailyPage
            {...pageProps}
            title={'AE Fuel Consumption'}
            page={isEMS ? 4 : isSingleEngine ? 6 : 7}
          >
            <HChartAEFuelCons
              ref={refChart}
              data={currAsset.dataSummary || undefined}
              columns={columns}
              isDailyReport
            />
          </ReportDailyPage>

          {currAsset.massId === '103' && (
            <>
              <ReportDailyPage
                {...pageProps}
                title={'Port Event Engine'}
                page={isEMS ? 5 : isSingleEngine ? 7 : 8}
              >
                <EventEngine data={portEventData} />
              </ReportDailyPage>

              <ReportDailyPage
                {...pageProps}
                title={'Center Event Engine'}
                page={isEMS ? 6 : isSingleEngine ? 8 : 9}
              >
                <EventEngine data={centerEventData} />
              </ReportDailyPage>

              <ReportDailyPage
                {...pageProps}
                title={'Starboard Event Engine'}
                page={isEMS ? 7 : isSingleEngine ? 9 : 10}
              >
                <EventEngine data={starboardEventData} />
              </ReportDailyPage>
            </>
          )}

          {/* {gatewayId && <HiddenText id='gatewayId'>{gatewayId}</HiddenText>} */}
          {!isLoading && <HiddenText id='finished'>done</HiddenText>}
        </>
      ) : (
        <h1>Something Wrong. Data asset summary empty</h1>
      )}
    </>
  );
};

export const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: calc(100vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

export const HiddenText = styled.div`
  display: none;
`;

export default ReportDaily;
