import { Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useFilter from '../hooks/useFilter';
import useAsset from '../hooks/useAsset';
import SeriesSvc from '../services/SeriesSvc';
import moment from 'moment';
import { getSource } from '../helpers/asset';

interface IProps {
  gps?: {
    count?: number;
    lastTimestamp?: number;
  };
  rpm?: {
    count?: number;
    lastTimestamp?: number;
  };
  flowmeter?: {
    count?: number;
    lastTimestamp?: number;
  };
  ae?: {
    count?: number;
    lastTimestamp?: number;
  };
  loading: boolean;
  pathname: string;
}

const dateFormat = 'DD/MM/YY, HH:mm';

const DataCompletionInfo: React.FC<IProps> = ({
  gps,
  rpm,
  flowmeter,
  ae,
  loading,
  pathname,
}) => {
  const { dataFilter } = useFilter();
  const { currentAsset, generateSeriesParam, currentDataTimezone } = useAsset();
  const [gatewayCount, setGatewayCount] = useState<{
    count: number;
    lastTimestamp: number;
    timestamps: number[];
  }>({
    count: 0,
    lastTimestamp: 0,
    timestamps: [],
  });
  const [powerCount, setPowerCount] = useState<{
    count: number;
    lastTimestamp: number;
    timestamps: number[];
  }>({
    count: 0,
    lastTimestamp: 0,
    timestamps: [],
  });

  const calculatePercentage = (dataLength: number, type: string) => {
    const startAt = Math.floor(
      Number(dataFilter.range.startAt.valueOf()) / 1000
    );
    const endAt = Math.floor(Number(dataFilter.range.endAt.valueOf()) / 1000);
    let interval = 0;

    if (dataFilter.interval === 'MINUTE') {
      interval = Math.floor((endAt - startAt) / 60);
    } else if (dataFilter.interval === 'HOUR') {
      interval = Math.floor((endAt - startAt) / 3600);
    } else {
      interval = Math.ceil((endAt - startAt) / 86400);
      if (moment(dataFilter.range.startAt).hours() === 0) {
        dataLength = dataLength - 1;
      }
    }

    if (pathname.includes('/report-chart') || pathname.includes('/dashboard')) {
      const { seriesParam } = generateSeriesParam({
        start: Math.floor(
          moment(dataFilter.range.startAt).valueOf()
        ).toString(),
        end: Math.floor(moment(dataFilter.range.endAt).valueOf()).toString(),
        aggregatedUnit: dataFilter.interval,
      });

      // if (
      //   !['gateway', 'power'].includes(type) &&
      //   !pathname.includes('/dashboard')
      // ) {
      // }
      interval =
        interval /
        (getSource() === 'satellite' ? 1 : seriesParam.aggregatedLength ?? 1);
    }

    const result = (dataLength / interval) * 100;

    return result > 100 ? 100 : result;
  };

  const dataFetchedRef = useRef(false);

  const [loadingDataCompletion, setLoadingDataCompletion] = useState(false);

  const fetchDataCompletion = async (
    devcType: string,
    devcUniqueId: string
  ) => {
    try {
      setLoadingDataCompletion(true);
      let aggregatedLength = null;

      if (
        pathname.includes('/report-chart') ||
        pathname.includes('/dashboard')
      ) {
        const { seriesParam } = generateSeriesParam({
          start: moment(dataFilter.range.startAt).valueOf().toString(),
          end: moment(dataFilter.range.endAt).valueOf().toString(),
          aggregatedUnit: dataFilter.interval,
        });
        aggregatedLength = seriesParam.aggregatedLength;
      }

      const startAt = Math.floor(
        Number(dataFilter.range.startAt.valueOf()) / 1000
      );
      const endAt = Math.floor(Number(dataFilter.range.endAt.valueOf()) / 1000);

      const timezoneCalc =
        currentDataTimezone > 7 ? currentDataTimezone - 7 : 0;

      const newStart = startAt - 60 * 60 * timezoneCalc;
      const newEnd = endAt - 60 * 60 * timezoneCalc;

      const response = await SeriesSvc.getAggregatedDataByPeriod(
        devcType,
        newStart,
        newEnd,
        {
          params: {
            aggregatedUnit: dataFilter.interval,
            device: [devcUniqueId],
            aggregatedLength,
            timezone: currentDataTimezone,
          },
        }
      );
      if (response.data.data && response.data.data.series) {
        const dataLength = response.data.data.series.length;
        if (dataLength > 0) {
          if (devcType === 'gateway') {
            setGatewayCount({
              count: response.data.data.count,
              lastTimestamp:
                response.data.data.series[dataLength - 1].timestamp * 1000,
              timestamps: response.data.data.series.map(
                (item: any) => item.timestamp
              ),
            });
          } else {
            setPowerCount({
              count: response.data.data.count,
              lastTimestamp:
                response.data.data.series[dataLength - 1].timestamp * 1000,
              timestamps: response.data.data.series.map(
                (item: any) => item.timestamp
              ),
            });
          }
        }
      }
    } catch (error) {
      console.error('failed get data completion', error);
    } finally {
      setLoadingDataCompletion(false);
    }
  };

  useEffect(() => {
    if (currentAsset && currentAsset.massId && currentAsset.massDevice) {
      // if (dataFetchedRef.current) return;
      // dataFetchedRef.current = true;

      const foundGateway = currentAsset.massDevice.find(
        (item: any) => item.devcType === 'gateway'
      );
      const foundPower = currentAsset.massDevice.find(
        (item: any) => item.devcType === 'power'
      );

      if (foundGateway) {
        fetchDataCompletion('gateway', foundGateway.devcUniqueId);
      }

      if (foundPower) {
        fetchDataCompletion('power', foundPower.devcUniqueId);
      }
    }
  }, [currentAsset, dataFilter.range.startAt, dataFilter.range.endAt]);

  const dataCompletion: {
    type: string;
    percentage: number;
    lastUpdated: string;
  }[] = useMemo(() => {
    const currentStartDate = Math.floor(
      moment(dataFilter.range.startAt).valueOf() / 1000
    );
    const currentEndDate = Math.floor(
      moment(dataFilter.range.endAt).valueOf() / 1000
    );

    const gatewayDataLength = gatewayCount.timestamps.filter((item) => {
      if (item > currentStartDate && item < currentEndDate) {
        return item;
      }
    }).length;

    const powerDataLength = powerCount.timestamps.filter((item) => {
      if (item > currentStartDate && item < currentEndDate) {
        return item;
      }
    }).length;

    return [
      {
        type: 'Gateway',
        percentage: calculatePercentage(gatewayDataLength, 'gateway'),
        lastUpdated: gatewayCount.lastTimestamp
          ? moment(gatewayCount.lastTimestamp).format(dateFormat)
          : '-',
      },
      {
        type: 'GPS',
        percentage: calculatePercentage(gps?.count || 0, 'gps'),
        lastUpdated: gps?.lastTimestamp
          ? moment(gps?.lastTimestamp).format(dateFormat)
          : '-',
      },
      {
        type: 'RPM',
        percentage: calculatePercentage(rpm?.count || 0, 'rpm'),
        lastUpdated: rpm?.lastTimestamp
          ? moment(rpm?.lastTimestamp).format(dateFormat)
          : '-',
      },
      {
        type: 'Flowmeter',
        percentage: calculatePercentage(flowmeter?.count || 0, 'flowmeter'),
        lastUpdated: flowmeter?.lastTimestamp
          ? moment(flowmeter?.lastTimestamp).format(dateFormat)
          : '-',
      },
      {
        type: 'AE',
        percentage: calculatePercentage(ae?.count || 0, 'ae'),
        lastUpdated: ae?.lastTimestamp
          ? moment(ae?.lastTimestamp).format(dateFormat)
          : '-',
      },
      {
        type: 'Power',
        percentage: calculatePercentage(powerDataLength, 'power'),
        lastUpdated: powerCount.lastTimestamp
          ? moment(powerCount.lastTimestamp).format(dateFormat)
          : '-',
      },
    ];
  }, [gatewayCount, powerCount, gps, flowmeter, rpm, ae]);

  return loading ? (
    <div>
      <Spin />
      <div>Loading Data...</div>
    </div>
  ) : (
    <DataCompletionWrapper>
      {/* <CompletionItem>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>Data Completion</div>
      </CompletionItem> */}
      {dataCompletion.map((item, idx) => (
        <CompletionItem key={idx}>
          <Title>
            {item.type}: {item.percentage.toFixed(2)}%
          </Title>
          <span style={{ opacity: 0.6 }}>Last Data: {item.lastUpdated}</span>
        </CompletionItem>
      ))}
    </DataCompletionWrapper>
  );
};

const Title = styled.div`
  font-weight: normal;
  font-size: 12px;
`;

const CompletionItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 0px 10px;
  height: 45px;
  background-color: white;
  font-size: 10px;

  div {
    font-size: 10px !important;
  }

  span {
    font-size: 10px;
  }
`;

const DataCompletionWrapper = styled.div`
  display: grid;
  gap: 1px;
  background-color: #e8ecf3;
  grid-template-columns: repeat(6, 1fr);
  border-bottom: 1px solid #e8ecf3;
`;

export default DataCompletionInfo;
