import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  message,
  Switch,
  DatePicker,
} from 'antd';
import { IOperationDetail } from '../../../types/operationMode.type';
import useAuth from '../../../hooks/useAuth';
import { MasterReportTemplateSvc } from '../../../services/MasterReportSvc';
import useAsset from '../../../hooks/useAsset';
import moment from 'moment';
import MasterOperatrionModeSvc from '../../../services/OperationModeSvc';

interface Props {
  isOpen: boolean;
  data?: IOperationDetail;
  afterSubmit: () => void;
  compId: string;
  mrodMrohId: string;
}

const ModalOperationModeDetail: React.FC<Props> = ({
  isOpen,
  data,
  afterSubmit,
  compId,
  mrodMrohId,
}) => {
  const { handlePermission } = useAuth();
  const [form] = Form.useForm<IOperationDetail>();
  const [disabledSave, setDisabledSave] = useState<boolean>(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  async function handleOnSubmit() {
    try {
      setLoadingSubmit(true);
      const values = await form.validateFields();
      const currentData = {
        mrodMrohId: mrodMrohId,
        mrodName: values.mrodName,
        mrodRpmMax: Number(values.mrodRpmMax),
        mrodConsRate: Number(values.mrodConsRate),
      };

      if (data) {
        await MasterOperatrionModeSvc.updateMasterDetail(
          data.mrodId,
          currentData
        );
      } else {
        await MasterOperatrionModeSvc.addMasterDetail(currentData);
      }

      handleReset();
    } catch (error) {
      console.error('failed submit operation mode detail', error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  function handleReset() {
    form.resetFields();
    afterSubmit();
  }

  useEffect(() => {
    form.setFieldValue('mrodName', data?.mrodName);
    form.setFieldValue('mrodRpmMax', data?.mrodRpmMax);
    form.setFieldValue('mrodConsRate', data?.mrodConsRate);
  }, [data]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  return (
    <Modal
      width={'45%'}
      title={
        data && data.mrodMrohId ? 'Edit Operation Mode' : 'Add Operation Mode'
      }
      open={isOpen}
      onCancel={handleReset}
      className='custom-modal'
      footer={[
        <Button
          style={{ background: '#FFFFFF', color: '#000000' }}
          onClick={handleReset}
        >
          Cancel
        </Button>,
        <Button
          loading={loadingSubmit}
          disabled={
            (!handlePermission(['master_rpm_operation_detail'], 'create') &&
              !handlePermission(['master_rpm_operation_detail'], 'update')) ||
            disabledSave
          }
          onClick={handleOnSubmit}
          type='primary'
        >
          {data && data.mrodMrohId ? 'Update' : 'Create'}
        </Button>,
      ]}
    >
      <Form layout='vertical' onFieldsChange={handleFormChange} form={form}>
        <Form.Item
          label='Name'
          name='mrodName'
          rules={[{ required: true, message: 'Please input Name!' }]}
        >
          <Input placeholder='Operation Mode Name' onChange={() => {}} />
        </Form.Item>
        <Form.Item
          label='RPM Max'
          name='mrodRpmMax'
          rules={[
            { required: true, message: 'Please input RPM MAX!' },
            // { type: 'number', message: 'RPM Max must be a number!' },
          ]}
        >
          <Input placeholder='RPM Maximum' type='number' onChange={() => {}} />
        </Form.Item>
        <Form.Item
          label='Consumption Rate'
          name='mrodConsRate'
          rules={[
            { required: true, message: 'Please Consumption Rate!' },
            // { type: 'number', message: 'Cons rate must be a number!' },
          ]}
        >
          <Input placeholder='Consumption Rate' onChange={() => {}} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalOperationModeDetail;
